import React from 'react';

import './style.scss';

class FAQ extends React.Component {
  constructor(props) {
    super(props);
    this._refs = [];
  }

  componentDidMount() {
    this._refs.forEach((element) => {
      element.addEventListener('click', (event) => {
        element.classList.toggle('item-opened');
      });
    });
  }

  render() {
    return (
      <main id="faq">
        <div className="container">
          <div className="row">
            <h1>Perguntas frequentes</h1>
            <div className="items">

              <article id="item-1" className="item" ref={(ref) => this._refs.push(ref)}>
                <div
                  id="heading-1"
                  className="question"
                  data-toggle="collapse"
                  data-target="#collapse-1"
                  aria-expanded="true"
                  aria-controls="collapse"
                >
                  <div className="icon">
                    <div className="horizontal" />
                    <div className="vertical" />
                  </div>
                  <span className="title">O site da Unindo Sonhos é confiável?</span>
                </div>
                <div
                  id="collapse-1"
                  className="answer"
                  area-labelledby="heading-1"
                  data-parent="#answers"
                >
                  <span className="content">
                    <p>
                      Sim! É extremamente seguro e confiável, pois utiliza protocolo SSL
                      em todas as conexões que trafegam suas informações pessoais e
                      confidenciais. Todas as informações são criptografadas e não
                      poderão ser visualizadas por terceiros.Esta tecnologia é a mesma
                      utilizada pelos grandes bancos em suas plataformas digitais.Todas
                      as suas informações são arquivadas em bases de dados
                      criptografadas altamente seguras, na Amazon Web Services,
                      seguindo as melhores práticas da indústria para codificação, tal
                      como o OWASP Development Guide.Também adotamos
                      procedimentos para utilizar o hashing – algoritmo SHA-256 – para
                      ajudar na proteção da integridade de quaisquer dados sensíveis.
                                            </p>
                  </span>
                </div>
              </article>

              <article id="item-2" className="item" ref={(ref) => this._refs.push(ref)}>
                <div
                  id="heading-2"
                  className="question"
                  data-toggle="collapse"
                  data-target="#collapse-2"
                  aria-expanded="true"
                  aria-controls="collapse"
                >
                  <div className="icon">
                    <div className="horizontal" />
                    <div className="vertical" />
                  </div>
                  <span className="title">Como funciona um bolão online e o sistema de apostas?</span>
                </div>
                <div
                  id="collapse-2"
                  className="answer"
                  area-labelledby="heading-2"
                  data-parent="#answers"
                >
                  <span className="content">
                    <p>
                      Bolão é uma modalidade de apostas, onde vários apostadores se
                      juntam para fazer jogos e dividirem o valor total entre si,
                      aumentando assim a probabilidade de acertos. O valor pago por
                      cada apostador chama-se cota. Em caso de premiação, o valor é
                      dividido entre o número de apostadores (cotas) pré-determinados.
                      No bolão online, a compra de cotas é feita virtualmente em nosso
                      site especializado. Disponibilizamos vários bolões, com diferentes
                      valores e números de cotas, cada grupo possui uma quantidade de
                      jogos, que podem ser previamente visualizados.
                                            </p>
                    <p>
                      Para participar das apostas, primeiramente deve-se efetuar o
                      cadastro em nosso site, do computador ou celular: É seguro, fácil e
                      rápido!
                                            </p>
                    <p>
                      Logo após o cadastro, você terá acesso aos nossos bolões, onde
                      estará disponível número de cotas e especificação de jogos e datas
                      de sorteio pela Loteria Federal.
                                            </p>
                    <p>
                      A opção de pagamento ficará disponível logo após a escolha do
                      bolão: Crédito Unindo Sonhos, Cartão de crédito ou transferência.
                                            </p>
                    <p>
                      Pronto! Após a confirmação do seu pagamento, sua participação
                      nos bolões da Unindo Sonhos está concretizada. Agora é só
                      aguardar e torcer. Boa sorte!
                                            </p>
                  </span>
                </div>
              </article>

              <article id="item-3" className="item" ref={(ref) => this._refs.push(ref)}>
                <div
                  id="heading-3"
                  className="question"
                  data-toggle="collapse"
                  data-target="#collapse-3"
                  aria-expanded="true"
                  aria-controls="collapse"
                >
                  <div className="icon">
                    <div className="horizontal" />
                    <div className="vertical" />
                  </div>
                  <span className="title">Como funcionam as cotas e a divisão da premiação, caso ocorra?</span>
                </div>
                <div
                  id="collapse-3"
                  className="answer"
                  area-labelledby="heading-3"
                  data-parent="#answers"
                >
                  <span className="content">
                    <p>
                      Cotas de bolões são parcelas iguais de um valor total de apostas.
                      Ao lado dos valores, estão disponíveis as quantidades de cotas
                      correspondentes a cada bolão. Os apostadores que comprarem,
                      concorrerão com todas as apostas contidas naquele bolão.
                      Caso haja premiação, a participação de cada apostador será
                      proporcional ao número de cotas adquiridas no bolão.
                                            </p>
                    <p>Por exemplo:</p>
                    <p>Um bolão tem 10 cotas no valor de R$10,00 cada, para concorrer as seguintes apostas:</p>
                    <p>
                      1 jogo de 18 números
                                                <br />
                      4 jogos de 17 números
<br />
                      40 jogos de 15 números
</p>
                    <p>
                      Se um apostador comprar 1 cota, ele estará adquirindo 10% deste
                      bolão, então em caso de premiação, ele receberá 10% do prêmio
                      total.
                                            </p>
                    <p>
                      Digamos que o prêmio total do grupo foram de 50 mil reais, a
                      premiação de cada cota será 50 mil dividido pelo número de cotas
                      deste bolão, que no caso são 10.
                                                <br />
                      O apostador que comprou 1 cota de 10 reais, receberá 5 mil reais.
<br />
                      O apostador que comprou 2 cotas de 10 reais, receberá 10 mil reais.
<br />
                      O apostador que comprou 5 cotas de 10 reais, receberá 25 mil reais.
<br />
                      E assim por diante.
</p>
                    <p />
                  </span>
                </div>
              </article>

              <article id="item-4" className="item" ref={(ref) => this._refs.push(ref)}>
                <div
                  id="heading-4"
                  className="question"
                  data-toggle="collapse"
                  data-target="#collapse-4"
                  aria-expanded="true"
                  aria-controls="collapse"
                >
                  <div className="icon">
                    <div className="horizontal" />
                    <div className="vertical" />
                  </div>
                  <span className="title">Como pagar?</span>
                </div>
                <div
                  id="collapse-4"
                  className="answer"
                  area-labelledby="heading-4"
                  data-parent="#answers"
                >
                  <span className="content">

                    <p><strong>Créditos Unindo Sonhos</strong></p>
                    <p>A opção é para quem já participou e já foi premiado através dos nossos bolões da Unindo Sonhos.</p>
                    <p>
                      Essa forma de pagamento facilita e agiliza o processo de finalização
                      das apostas e você pode acompanhar seu saldo através do Menu
                      da Sua Conta da Unindo Sonhos.
                                            </p>
                    <p>Com essa opção, suas apostas são confirmadas imediatamente, até mesmo fora do horário comercial.</p>
                    <p>Para adquirir créditos da Unindo Sonhos, você pode utilizar o mesmo procedimento das formas de pagamento informadas a seguir.</p>

                    <p><strong>Transferência entre contas</strong></p>
                    <p>
                      Se você pagou o seu boleto até às 20h, o pagamento será processado no mesmo dia. Assim, a efetivação do pagamento
                      vai levar até 3 dias úteis após seu processamento. Mas se você fez o pagamento depois deste horário, em feriados
                      ou finais de semana, será processado no próximo dia útil.
                                            </p>

                    <p><strong>Cartão de crédito</strong></p>
                    <p>
                      Após informar os dados do cartão para pagamento, a aprovação pode ocorrer em até 20 minutos, após análise interna
                      da administradora.
                                            </p>
                    <p><strong>PIX</strong></p>
                    <p>
                      O Pix funciona todos os dias, inclusive feriados, e em qualquer horário*. Assim, o dinheiro é compensado no mesmo
                      dia em que a transferência é feita.
                                            </p>

                  </span>
                </div>
              </article>

              <article id="item-5" className="item" ref={(ref) => this._refs.push(ref)}>
                <div
                  id="heading-5"
                  className="question"
                  data-toggle="collapse"
                  data-target="#collapse-5"
                  aria-expanded="true"
                  aria-controls="collapse"
                >
                  <div className="icon">
                    <div className="horizontal" />
                    <div className="vertical" />
                  </div>
                  <span className="title">Não consigo fazer meu login, e agora?</span>
                </div>
                <div
                  id="collapse-5"
                  className="answer"
                  area-labelledby="heading-5"
                  data-parent="#answers"
                >
                  <span className="content">
                    <p>
                      Ao preencher seus dados, confira se digitou tudo corretamente.
                      Se não funcionar, verifique se o email foi digitado corretamente no
                      momento do cadastro.
                                            </p>
                    <p>
                      Se você trocou seu e-mail recentemente, tente fazer seu login
                      utilizando o endereço do e-mail anterior. Nos dois últimos casos,
                      atualize o seu cadastro.
                                            </p>
                    <p>Se você esqueceu sua senha, basta clicar em &quot;Esqueci minha senha&quot;, que encontra-se junto a caixa de login.</p>
                    <p>Se o problema persistir, nos envie uma mensagem em nossos canais de atendimento.</p>
                  </span>
                </div>
              </article>

              <article id="item-6" className="item" ref={(ref) => this._refs.push(ref)}>
                <div
                  id="heading-6"
                  className="question"
                  data-toggle="collapse"
                  data-target="#collapse-6"
                  aria-expanded="true"
                  aria-controls="collapse"
                >
                  <div className="icon">
                    <div className="horizontal" />
                    <div className="vertical" />
                  </div>
                  <span className="title">O que acontece se as cotas do bolão que comprei não forem vendidas? Vou concorrer mesmo assim?</span>
                </div>
                <div
                  id="collapse-6"
                  className="answer"
                  area-labelledby="heading-6"
                  data-parent="#answers"
                >
                  <span className="content">
                    <p>
                      Sim! Não se preocupe, mesmo que tenha sido vendida apenas uma
                      cota do bolão que você está participando, todas as apostas do
                      grupo concorrerão ao concurso em questão. Em caso de
                      premiação, você receberá o valor proporcional a quantidade de
                      cotas que adquiriu.
                                            </p>
                  </span>
                </div>
              </article>

              <article id="item-7" className="item" ref={(ref) => this._refs.push(ref)}>
                <div
                  id="heading-7"
                  ref={(ref) => this._refs.push(ref)}
                  className="question"
                  data-toggle="collapse"
                  data-target="#collapse-7"
                  aria-expanded="true"
                  aria-controls="collapse"
                >
                  <div className="icon">
                    <div className="horizontal" />
                    <div className="vertical" />
                  </div>
                  <span className="title">E se eu for premiado? Como recebo meu prêmio?</span>
                </div>
                <div
                  id="collapse-7"
                  className="answer"
                  area-labelledby="heading-7"
                  data-parent="#answers"
                >
                  <span className="content">
                    <p>
                      A Unindo Sonhos realiza a apuração e faz o rateio entre os
                      apostadores automaticamente. Caso haja premiação, todos serão
                      comunicados via e-mail. O apostador também pode verificar seus
                      prêmios aqui no nosso site, através da opção &quot;Minhas Apostas&quot; no
                      menu da sua conta Unindo Sonhos.
                                            </p>
                    <p>
                      O rateio do prêmio é creditado automaticamente na aba “Carteira do
                      Cliente”, que encontra-se no menu da sua conta da Unindo Sonhos.
                                            </p>
                    <p>
                      Sobre a premiação, o ganhador tem duas alternativas:
                                            </p>
                    <p>
                      1 - Saldo:<br />
                      Você pode manter o valor do prêmio em sua carteira na sua conta
                      da Unindo Sonhos, para utilizá-lo em novas apostas quando quiser.
                                            </p>
                    <p>
                      2 – Resgate:<br />
                      O resgate pode ser solicitado também através da opção &quot;Carteira
                      do Cliente&quot; do menu do usuário, onde o ganhador informará os
                      dados de uma conta-corrente em sua titularidade. Para sua
                      comodidade, em um prazo de até 15 dias úteis para a transferência,
                      o valor será creditado diretamente na conta bancária informada. Não
                      será cobrada qualquer taxa em cima da premiação, porém temos uma
                      pequena taxa de resgate, que são de R$ 5,00 (Cinco reais), independente
                      do valor e do banco solicitado.
                                            </p>
                  </span>
                </div>
              </article>

              <article id="item-8" className="item" ref={(ref) => this._refs.push(ref)}>
                <div
                  id="heading-8"
                  className="question"
                  data-toggle="collapse"
                  data-target="#collapse-8"
                  aria-expanded="true"
                  aria-controls="collapse"
                >
                  <div className="icon">
                    <div className="horizontal" />
                    <div className="vertical" />
                  </div>
                  <span className="title">E se eu fizer meu pagamento após o prazo estipulado?</span>
                </div>
                <div
                  id="collapse-8"
                  className="answer"
                  area-labelledby="heading-8"
                  data-parent="#answers"
                >
                  <span className="content">
                    <p>
                      Caso não dê tempo de realizar o pagamento no prazo estipulado,
                      você não concorrerá com o sorteio selecionado, porém ficará com
                      saldo para uma próxima aposta e o valor pago será convertido em
                      saldo na aba “Carteira do Cliente” do menu da sua conta da Unindo
                      Sonhos.
                                            </p>
                  </span>
                </div>
              </article>

              <article id="item-9" className="item" ref={(ref) => this._refs.push(ref)}>
                <div
                  id="heading-9"
                  className="question"
                  data-toggle="collapse"
                  data-target="#collapse-9"
                  aria-expanded="true"
                  aria-controls="collapse"
                >
                  <div className="icon">
                    <div className="horizontal" />
                    <div className="vertical" />
                  </div>
                  <span className="title">Em quanto tempo são confirmados os pagamentos?</span>
                </div>
                <div
                  id="collapse-9"
                  className="answer"
                  area-labelledby="heading-9"
                  data-parent="#answers"
                >
                  <span className="content">
                    <p>Os pagamentos são confirmados dentro dos prazos a seguir:</p>
                    <p> - Cartão de Crédito: no máximo 20 minutos após análise interna da Stone.</p>
                    <p>
                      - Boleto Bancário: Se você pagou o seu boleto até às 20h, o pagamento será processado no mesmo dia.
                      Assim, a efetivação do pagamento vai levar até 3 dias úteis após seu processamento; Mas se você fez
                      o pagamento depois deste horário, em feriados ou finais de semana, será processado no próximo dia útil.
                                            </p>
                    <p>
                      PIX: O Pix funciona todos os dias, inclusive feriados, e em qualquer horário*.
                      Assim, o dinheiro é compensado no mesmo dia em que a transferência é feita.
                                            </p>
                  </span>
                </div>
              </article>

            </div>
          </div>
        </div>
      </main>
    );
  }
}
export default FAQ;
