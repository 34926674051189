import moment from 'moment';

export default function formatDate(date, oldFormat = false, withHour = false, onlyHour = false, shortDate = false) {
  if (withHour) {
    return moment(date, !oldFormat ? null : 'DD/MM/YYYY H:m:s').format('DD/MM/YYYY HH:mm');
  }

  if (shortDate) {
    return moment(date, !oldFormat ? null : 'DD/MM/YYYY H:m:s').format('DD/MM/YY');
  }

  if (onlyHour) {
    return moment(date, !oldFormat ? null : 'DD/MM/YYYY H:m:s').format('HH:mm[h]');
  }

  return moment(date, !oldFormat ? null : 'DD/MM/YYYY H:m:s').format('DD/MM/YYYY');
}
