import './styles.css'

export default function Manutencao() {
  return (
    <div className="manutencao container">
      <div>
        <h1>Desculpe, estamos em manutenção!</h1>
        <p>Estamos trabalhando para melhorar o nosso site e retornaremos em breve.</p>
        <p>Por favor, tente novamente mais tarde.</p>
      </div>
    </div>
  )
}
