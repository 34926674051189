/* eslint-disable consistent-return */
/* eslint-disable no-return-assign */
/* eslint-disable import/no-cycle */
export default function getLoggedUserInfo(onlyData = false) {
  if (localStorage.getItem('unindo_sonhos_data')) {
    return JSON.parse(localStorage.getItem('unindo_sonhos_data'));
  }

  if (!onlyData && typeof window !== 'undefined' && (window.location.pathname.indexOf('/dashboard') !== -1 || window.location.pathname.indexOf('/checkout') !== -1)) {
    window.location.href = `${process.env.REACT_APP_ON_BOARDING_URL}/login?action=auth&redirect_to=${encodeURIComponent(window.location.href)}`;
  }
}
