/* eslint-disable no-promise-executor-return */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable consistent-return */
/* eslint-disable no-use-before-define */
/* eslint-disable no-return-assign */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Message, Tooltip, Whisper } from 'rsuite';
import { CheckCircleOutline, ContentCopyOutlined, CopyAll } from '@mui/icons-material';
import CopyToClipboard from 'react-copy-to-clipboard';
import { CircularProgress } from '@mui/material';
import Button from '../../../components/Button';
import Timer from '../../../components/Timer';
import account from '../../../services/account';
import { history } from '../../../helpers/history';
import DefaultResult from './DefaultResult';

function PixContent({
  expirated, codeCopy, primaryMethod, setExpirated, order, setCodeCopy,
}) {
  return (
    <>
      {!expirated ? (
        <>
          {!isMobile && (
            <div className="expiration-qrcode">
              <img src={primaryMethod?.qrcode_base64} alt="QRCode PIX" height={200} width={200} />
              <div className="expiration-time">
                <span className="label">Atenção seu código irá expirar em:</span>
                <Timer
                  key="pix-timer"
                  onlyText={false}
                  totalTime={primaryMethod?.seconds_to_expire}
                  deadline={primaryMethod?.countdown_seconds_to_expire}
                  onEnd={() => {
                    setExpirated(true);
                  }}
                />
              </div>
            </div>
          )}
          {isMobile && (
            <div
              className="pix-code"
              onClick={() => {
                navigator.clipboard.writeText(primaryMethod?.code);
                setCodeCopy(true);
                setTimeout(() => {
                  setCodeCopy(false);
                }, 2500);
              }}
            >
              <div className="expiration-time">
                <img src={primaryMethod?.qrcode_base64} alt="QRCode PIX" height={200} width={200} />
                <span className="label">Atenção seu código irá expirar em:</span>
                <Timer
                  key="pix-timer"
                  onEnd={() => setExpirated(true)}
                  deadline={primaryMethod?.countdown_seconds_to_expire}
                  withLabel
                />
              </div>

              {/* CÓDIGO DUPLICADO POR CONTA DO WEB BROWSER DO INSTAGRAM */}
              <CopyToClipboard
                text={primaryMethod?.code}
                onCopy={async () => {
                  setCodeCopy(true);
                  setTimeout(() => {
                    setCodeCopy(false);
                  }, 2500);
                }}
              >
                <span className={`pix-label ${codeCopy ? 'copied' : ''}`}>
                  {!codeCopy ? 'Clique aqui e copie o código PIX' : 'Código copiado com sucesso!'}
                </span>
              </CopyToClipboard>

              {/* CÓDIGO DUPLICADO POR CONTA DO WEB BROWSER DO INSTAGRAM */}
              <CopyToClipboard
                text={primaryMethod?.code}
                onCopy={async () => {
                  setCodeCopy(true);
                  setTimeout(() => {
                    setCodeCopy(false);
                  }, 2500);
                }}
              >
                <div id="pix-code-input">
                  <span>
                    {primaryMethod?.code}
                  </span>
                  {codeCopy ? <CheckCircleOutline style={{ fontSize: 16, color: '#209869' }} /> : <ContentCopyOutlined style={{ fontSize: 16, color: '#209869' }} />}
                </div>
              </CopyToClipboard>
            </div>
          )}
          <div className="warn">
            <Message showIcon type="warning">
              Apenas pagamentos com o código gerado ou QRCode serão reconhecidos. Caso o código dê erro, refaça a operação.
            </Message>
          </div>
        </>
      ) : (
        <div className="warn">
          <Message showIcon type="error">
            O seu código PIX expirou, refaça a operação.
          </Message>
        </div>
      )}
    </>
  );
}

export default function PixResult({ order: initialOrder }) {
  const [expirated, setExpirated] = useState(false);
  const [codeCopy, setCodeCopy] = useState(false);
  const [order, setOrder] = useState(initialOrder);

  const primaryMethod = order?.payments?.length === 1
    ? order?.payments[0]
    : order?.payments?.find((method) => method.payment_channel !== 'wallet');

  async function checkOrder() {
    if (primaryMethod?.status === 'completed') {
      return;
    }

    if (!primaryMethod.seconds_to_expire) {
      await new Promise((resolve) => setTimeout(resolve, 5000));
      getOrder();

      return;
    }

	  await new Promise((resolve) => setTimeout(resolve, 10000));
	  getOrder();
  }

  useEffect(() => {
    checkOrder();
  }, [order]);

  const getOrder = () => {
    account.getMyOrders(order?.id).then((response) => {
      setOrder(response.data);
    });
  };

  if (['completed', 'failed', 'canceled'].includes(primaryMethod?.status)) {
    return (
      <DefaultResult order={order} />
    );
  }

  if (!primaryMethod.seconds_to_expire) {
    return (<span className="loading"><CircularProgress /></span>);
  }

  return (
    <div id="pix-countdown">
      <h1 className="title">Faça o pagamento do pix.</h1>
      <p className="title">Esse código PIX ficará ativo por 7 minutos realize o pagamento e aguarde a confirmação em tela para garantir a sua compra.</p>
      <PixContent codeCopy={codeCopy} primaryMethod={primaryMethod} expirated={expirated} order={order} setCodeCopy={setCodeCopy} setExpirated={setExpirated} />
      <div className="actions">
        {!expirated
          ? <Button color="invert" width="100%" label="Ir para minha conta" action={() => window.location.href = `${process.env.REACT_APP_ON_BOARDING_URL}/dashboard`} />
          : <Button color="invert" width="100%" label="Ver bolões" action={() => history.push('/boloes')} />}
      </div>
    </div>
  );
}
