/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { CircularProgress } from '@mui/material';
import DefaultResult from './result/DefaultResult';
import PixResult from './result/pix';
import account from '../../services/account';
import { history } from '../../helpers/history';
import logo from '../../assets/images/logotipo-unindo-sonhos.png';

export default function SuccessStep() {
  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState({});

  useEffect(() => {
    setLoading(true);
    account.getMyOrders(id).then((response) => {
      setLoading(false);
      setOrder(response.data);
    });
  }, []);

  const primaryMethod = order?.payments?.length === 1
    ? order?.payments[0]
    : order?.payments?.find((method) => method.payment_channel !== 'wallet');

  return (
    <main id="checkout">
      <div id="steps">
        <figure onClick={() => history.push('/')}>
          <img src={logo} alt="Logotipo da Unindo Sonhos" />
        </figure>
        <section id="checkout-step-box">
          {loading ? <span className="loading"><CircularProgress /></span> : (
            <>
              {order && (
              <section id="checkout-step-success" className={`${loading ? 'loading' : ''}`}>
                {primaryMethod?.payment_channel === 'wallet' && <DefaultResult order={order} />}
                {primaryMethod?.payment_channel === 'Pix' && <PixResult order={order} />}
                {primaryMethod?.payment_channel === 'Boleto' && <DefaultResult order={order} />}
                {primaryMethod?.payment_channel === 'CreditCard' && <DefaultResult order={order} />}
              </section>
              )}
            </>
          )}
        </section>
      </div>
    </main>
  );
}
