import { types } from './types';
const initialState  = { 
    userData:    { },
    userBank:    [ ],
    userAddress: { },
    balance:       0
};

const reducer = (state = initialState, action = { }) => { 
    switch(action.type) {
        case types.SET_USER_DATA:
            return { ...state, userData: action.userData };
        case types.SET_USER_BANK:
            return { ...state, userBank: action.userBank};
        case types.SET_BALANCE:
            return { ...state, balance: action.balance };
        case types.ADD_USER_BANK:
            return { ...state, userBank: [ ...state.userBank, action.payload ] };
        case types.ADD_USER_ADDRESS:
            return { ...state, userAddress: action.userAddress };
        case types.REMOVE_USER_BANK:
            return { ...state, userBank: state.userBank.filter((item) => item !== action.payload) };
        case types.RESET:
            return initialState;
        default:
            return state;
    }
}
export default reducer;