/* eslint-disable react/react-in-jsx-scope */
import CloseIcon from '@material-ui/icons/Close'
import './style.scss'
import logo from '../../assets/images/icon-loteria.svg'

export default function Header({ data, handleModal }) {
//   console.log(data)
  return (
    <div className={`modal-title ${data.jackpot?.type_game && data.jackpot?.type_game.slug}`}>
      <span className="title">
        <img src={logo} alt="logotipo das loterias federais" width="20" />
        {' '}
        {data.jackpot?.name}
      </span>
      <span className="ref">
        <CloseIcon className="close-modal" onClick={handleModal} />
      </span>
    </div>
  )
}
