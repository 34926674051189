/* eslint-disable eqeqeq */
/* eslint-disable no-tabs */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable react/prop-types */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import React from 'react';
import moment from 'moment';
import { NavLink } from 'react-router-dom';

import logo from '../../assets/images/icon-loteria.svg';

import extensiveCurrency from '../../helpers/extensiveCurrency';
import JackpotTimeLeft from '../JackpotTimeLeft';
import './style.scss';
import PhoneIcon from '../../assets/images/icons/phone.svg';

class JackpotBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false, open: false,
    };
  }

  render() {
    const _type = {
      'dia-de-sorte': 'DIA DE SORTE', all: 'Todos os bolões', 'dupla-sena': 'DUPLA SENA', loteca: 'LOTECA', lotomania: 'LOTOMANIA', lotofacil: 'LOTOFÁCIL', quina: 'QUINA', timemania: 'TIMEMANIA', 'mega-sena': 'MEGA SENA', 'mega-sena-da-virada': 'MEGA SENA DA VIRADA', 'super-sete': 'SUPER SETE',
    };

    const { concourse } = this.props;

    return (
      <div
        className={`body ${concourse.type === 'all' ? 'all-concourses' : ''} ${concourse.type === 'mega-sena' && concourse.number == 2440 ? 'mega-sena-da-virada' : concourse.type + (concourse.type_number ? `-${concourse.type_number}` : '')}`}
      >
        <div className={`header ${concourse.type === 'all' ? 'all-concourses' : ''}`}>
          <div className="title">
            {concourse.type != 'all' ? <img src={logo} alt="logotipo das loterias federais" width="35" /> : ''}
            <div className={`text ${concourse.type === 'all' ? 'all-concourses' : ''}`}>
              {concourse.type != 'all' ? concourse.name.toUpperCase() : concourse.name}
              {concourse.type != 'all' ? (
                <div className="subtitle">
                  sorteio n
                  {' '}
                  {concourse.number}
                  .
                  {' '}
                  {moment(concourse.drawDate, 'DD/MM/YYYY H:m:s').format('DD/MM/YYYY')}
                </div>
              ) : ''}
            </div>
          </div>
        </div>
        {concourse.type != 'all' ? (
          <>
            <div className="content">
              <div className="prize">{extensiveCurrency(concourse.prize)}</div>
              <JackpotTimeLeft
                timeTillDate={concourse.drawDate}
                timeFormat="DD/MM/YYYY HH:mm:ss"
                clock={false}
              />
              <button
                className="concourse-button"
              >
                <NavLink to={`/boloes/${concourse.concourse_id}`} activeStyle={{ color: '#000000' }}>Comprar cotas</NavLink>
              </button>
            </div>
            {concourse?.has_mus_reward_jackpot && (
            <a target="_blank" href={process.env.REACT_APP_PLAY_URL || 'https://play.unindosonhos.com.br'} rel="noreferrer" className="jackpot-mus-card">
              <img src={PhoneIcon} width={12} height={18} alt="Moeda MUS" />
              <span>Benefícios exclusivos no app</span>
            </a>
            )}
          </>
        ) : ''}
      </div>
    );
  }
}

export default JackpotBox;
