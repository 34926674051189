import api from './api';

const couponService = {
  async checkCoupon(payload) {
    try {
      const { data } = await api.post('/coupon/check', payload);
      return data;
    } catch (error) {
      console.log(error);
      return false;
    }
  },
  async removeCoupon(payload) {
    try {
      const { data } = await api.post('/coupon/remove', payload);
      return data;
    } catch (error) {
      console.log(error);
      return false;
    }
  },
};

export default couponService;
