import React from 'react';
import './style.scss';

import CircularProgress from '@material-ui/core/CircularProgress';

class Button extends React.Component {
  render() {
    return (
      <button
        type={this.props.type}
        style={{ width: this.props.width, margin: this.props.margin }}
        className={`${this.props.loading ? 'with-progress' : ''} ${this.props.color}`}
        disabled={this.props.disabled || this.props.loading}
        onClick={this.props.action ? this.props.action : null}
        {...this.props}
      >
        { this.props.loading ? <CircularProgress className="progress" /> : this.props.label }
      </button>

    );
  }
}

export default Button;
