import React from 'react';
import SimpleReactValidator from 'simple-react-validator';

import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';

import { history }     from '../../helpers/history';
import Button          from '../../components/Button';
import AuthService     from '../../services/auth';

import logo from '../../assets/images/logotipo-unindo-sonhos.png';
import './style.scss';

class RescuePassword extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            email:    '',
            password: '',
            confirm:  '',
            token:    '',

            showPass:        false, 
            showConfirmPass: false,

            feedback: { show: false, severity: 'info', message: '' },
            loading:  false
        }

        const _params = { 
            validators: {
                confirmPassword: {
                    rule:    (value, params, validator) => this.state.password === this.state.confirm ? true : false ,
                    message: "As senhas não coninsidem.",
                    required: true
                }
            },
            messages: { 
                required: "Esse campo é obrigatório",
                email:    "Esse e-mail é inválido",
                min:      "É necessário ter no mínimo 6 caracteres"
            }
        };

        this.handleChange = this.handleChange.bind(this);
        this.send         = this.send.bind(this);
        this.validator    = new SimpleReactValidator(_params);
    }

    componentDidMount() {  
        this.props.match.params.token
            ? this.setState({ ...this.state, token: this.props.match.params.token })
            : this.setState({ ...this.state, feedback: { show: true, severity: 'warning', message: 'Você não tem acesso a esse serviço.' }});
    }

    handleChange(event, field) {
        this.setState({ ...this.state, [field]: event.target.value });
    }

    async send(event) {
        event.preventDefault();

        if(!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return;
        }

        const _input = {
            email:    this.state.email,
            password: this.state.password,
            token:    this.state.token
        }

        const response = await AuthService.resetPassword(_input);
        if(response.data) {
            this.setState({ ...this.state, feedback: { show: true, severity: 'success', message: 'Sua senha foi atualizada com sucesso!' }});
            setTimeout(() => this.setState({ ...this.state, feedback: { show: false, severity: 'info', message: '' }}), 5000);
            setTimeout(() => history.push('/'), 6000);
        }
        else {
            !response.token
                ? this.setState({ ...this.state, feedback: { show: true, severity: 'warning', message: response.message }})
                : this.setState({ ...this.state, feedback: { show: true, severity: 'warning', message: 'O serviço de recuperação de senhas está offline. Por gentileza, tente novamente mais tarde.' }});
            setTimeout(() => this.setState({ ...this.state, feedback: { show: false, severity: 'info', message: '' }}), 5000);
        }
    }

    render() {
        const { feedback, email, loading, token } = this.state;
        return (
            <main id="forgot-password">

                <figure onClick={ event => history.push('/') }>
                    <img src={ logo }   alt="Logotipo da Unindo Sonhos" />
                </figure>
            
                <div className="box">

                    <form id="formulary" onSubmit={ this.send } noValidate>

                        <div className="form-group">
                            <h1>Insira uma nova senha!</h1>
                            <p className="description">
                                Para recuperar o acesso a nossa plataforma, insira seu e-mail e sua nova senha no formulário.
                            </p>
                            <Collapse className="feedback" in={ feedback.show }>
                                <Alert severity={ feedback.severity }>{ feedback.message }</Alert>
                            </Collapse>
                        </div>

                        <div className="form-group">
                            <label>E-mail*</label>
                            <input type="email" className="form-control" value={ email } onChange={ event => this.handleChange(event, 'email') } />
                            { this.validator.message('E-mail', this.state.email, 'required|email') }
                        </div>

                        <div className="form-group column">
                            <label>Senha*</label>
                            <span className="eye">
                                <input type={ this.state.showPass ? 'text' : 'password' } value={ this.state.password } className="form-control" autoComplete="current-password" onChange={ event => this.handleChange(event, 'password') }/>
                                {
                                    this.state.showPass ? <VisibilityOffOutlinedIcon onClick={ event => this.setState({ ...this.state, showPass: !this.state.showPass }) } />
                                                        : <VisibilityOutlinedIcon    onClick={ event => this.setState({ ...this.state, showPass: !this.state.showPass }) } />
                                }
                                { this.validator.message('Password', this.state.password, 'required|confirmPassword|min:6') }
                            </span>
                        </div>

                        <div className="form-group column">
                            <label>Confirme sua senha*</label>
                            <span className="eye">
                                <input type={ this.state.showConfirmPass ? 'text' : 'password' } value={ this.state.confirm } className="form-control" autoComplete="current-password" onChange={ event => this.handleChange(event, 'confirm') }/>
                                {
                                    this.state.showConfirmPass 
                                        ? <VisibilityOffOutlinedIcon onClick={ event => this.setState({ ...this.state, showConfirmPass: !this.state.showConfirmPass }) } />
                                        : <VisibilityOutlinedIcon    onClick={ event => this.setState({ ...this.state, showConfirmPass: !this.state.showConfirmPass }) } />
                                }
                                { this.validator.message('Confirmação de Password', this.state.confirm, 'required|confirmPassword|min:6') }
                            </span>
                        </div>
                    
                        <div className="form-group actions">
                            <input type="hidden" className="form-control" defaultValue={ token } />
                            <Button type="submit" disabled={ loading } loading={ loading } color="green" label="Atualizar Senha" />
                        </div>

                    </form>
                    
                    <span className="goBack link" onClick={ event => history.goBack() }>Voltar</span>

                </div>
            </main>
        )
    }

}

export default RescuePassword;