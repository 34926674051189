import React    from 'react';
import Check from '@material-ui/icons/Check';

import phoneValidation from '../../services/phoneValidation';

import CloseIcon from '@material-ui/icons/Close';

import masks          from '../../helpers/masks';
import Button         from '../Button';

import './style.scss';

class PhonePopUp extends React.Component {

    constructor(props) {
        super(props);
        this.state = { 
            closed: false,
            cpf:this.props.userData ? this.props.userData.cpf : '',
            phone:this.props.userData ? this.props.userData.phone : '',
            phoneDigit:this.props.userData ? this.props.userData.phone.substr(this.props.userData.phone.length - 4,this.props.userData.phone.length):'',
            phoneConfirm: false,
            codeSended:false,
            pinCode:'',
            codeConfirmed: false,
            pinWarning:{show:false, message:''},
            phoneWarning:{show:false, message:''},
            step:1
        };

        this.handlePhone            = this.handlePhone.bind(this);
        this.setPhone               = this.setPhone.bind(this);
        this.handlePinChange        = this.handlePinChange.bind(this);
        this.handlePinConfirm       = this.handlePinConfirm.bind(this);
        this.closeModal             = this.closeModal.bind(this);

    }

    setPhone(value){
        this.setState(
            {   ...this.state,
                phone:value,
                phoneDigit:value.substr(value.length - 4,value.length),
                phoneWarning:{
                    show:false,
                    message:''
                }
            }
        )
    }

    async handlePhone(){
        if(masks.removeMask.phone(this.state.phone).length < 11){
            this.setState({...this.state,phoneWarning:{show:true,message:'Telefone inválido'}})
            return
        }
        //Salva o telefone através da API
        phoneValidation.sendPinCode(masks.removeMask.phone(this.state.phone))

        this.setPhone(masks.removeMask.phone(this.state.phone))

        this.setState({...this.state,fillPhone:false,phoneConfirm:true,step:3})
    }

    handlePinChange(event) {
        //limpa as validções
        this.setState({...this.state,pinWarning:{show:false}})

        const value = event.target.value
        if(value.length <= 4){
            this.setState({...this.state, pinCode:value})
        }
    }

    async handlePinConfirm(){
        //validção do pin na API
        const { data } = await phoneValidation.validatePinCode(this.state.pinCode,this.state.cpf);

        if(data.message === 'success'){
            // Tudo certo!
            await phoneValidation.setPhone(masks.removeMask.phone(this.state.phone));
            this.closeModal()
        }else{
            //Código inválido
            this.setState({...this.state,pinWarning:{show:true,message:'Código inválido'}})
            return 
        }
    }

    closeModal(){
        this.setState({...this.state,step:4})
        setTimeout(() => {  
            this.setState({...this.state,closed:true})
        }, 3000);
    }

    render() {        
        const { phone,phoneDigit,pinWarning,phoneWarning,pinCode,step,closed } = this.state
        return(
            !closed ? 
            <>
                {
                    step === 1 ?
                    <div id="container-modal" className={`box ${this.state.closed ? 'closed' : ''}`}>
                        <CloseIcon className="close" onClick={ () => this.setState({...this.state,closed:true}) } />

                        <span className="title">Seu número de telefone ainda não foi verificado</span>
                        <span className="subtitle">Ele será necessário para fazer transferências em sua carteira.</span>
                        
                        <Button type="button" width="120px" color="invert"  label="Cadastrar"  action={() => this.setState({...this.state,step:2})}>Cadastrar Telefone</Button>
                    </div>
                    : ''
                }
                {
                    step === 2 ?    
                    <div id="fillPhone-modal" className={ `animated ${ step === 2 ? 'fadeInUp' : 'fadeOutDown' }` }>
                        <CloseIcon className="close" onClick={ () => this.setState({...this.state,closed:true}) } />
                                
                        <h3>Insira seu número de telefone celular</h3>
                        <small>Informe seu número corretamente para a verificação.</small>
                        <input className={`phone-input ${phoneWarning.show ? 'phoneWarning' : ''}`} type="text" onChange={(event) => { this.setPhone(masks.phone(event.target.value))}} value={phone}/>
                        {phoneWarning.show ?  <span className="phone-warning-message">{phoneWarning.message}</span> : ''}

                        <Button type="button" width="120px" color="green"  label="Verificar"  action={ this.handlePhone } />

                    </div>
                    : ''
                }

                {
                    step === 3 ? 
                    <div className={` phone-validation-modal`}>
                        <CloseIcon className="close" onClick={ () => this.setState({...this.state,closed:true}) } />

                        <h1>Código enviado</h1>
                        <span>Enviamos um código via SMS para o telefone final <strong> {phoneDigit} </ strong ></span>
                        <small><strong>Insira o PIN de 4 dígitos para efetivar a verificação</strong></small>
                    
                        <input type="number" className={`phone-input ${pinWarning.show ? 'pinWarning' : ''}`} onChange={this.handlePinChange} value={pinCode}/>
                        
                        {pinWarning.show ?  <span className="pin-warning-message">{pinWarning.message}</span> : ''}
                        
                        <span className="actions">
                            <span className="link" onClick={ event => this.setState({...this.state,step:2}) }>Voltar</span>
                            <Button type="button" width="120px" color="green"  label="Verificar"  action={ this.handlePinConfirm } />
                        </span>
                    </div>
                    :''
                }

                {
                    step === 4 ? 
                    <div className={`success-modal`}>
                        <CloseIcon className="close" onClick={ () => this.setState({...this.state,closed:true}) } />

                        <h2>Verificado com sucesso</h2>
                        <Check className="check"/>
                        
                        <span className="actions">
                            <Button type="button" width="120px" color="green"  label="Fechar"  action={ ()=>{this.setState({...this.state,closed:true})} } />
                        </span>
                    </div>
                    :''
                }
                
            </>
            : ''
        )
    }
}

export default PhonePopUp;