/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */

import React, { useEffect, useState } from 'react'
import moment from 'moment'

import { CSSTransition } from 'react-transition-group'

import Modal from '@material-ui/core/Modal'
import Fade from '@material-ui/core/Fade'
import Backdrop from '@material-ui/core/Backdrop'
import Timeline from '@material-ui/lab/Timeline'
import TimelineItem from '@material-ui/lab/TimelineItem'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'
import TimelineConnector from '@material-ui/lab/TimelineConnector'
import TimelineContent from '@material-ui/lab/TimelineContent'
import TimelineDot from '@material-ui/lab/TimelineDot'
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import SearchIcon from '@material-ui/icons/Search'
import GetAppIcon from '@material-ui/icons/GetApp'

import winning from '../../assets/images/icons/winning.svg'
import waiting from '../../assets/images/icons/waiting.svg'
import noWinner from '../../assets/images/icons/no-winner.svg'
import cancelled from '../../assets/images/icons/cancelled.svg'
import hidden from '../../assets/images/icons/close-red.svg'

import jackpotServices from '../../services/jackpots'
import BettingsService from '../../services/bettings'

import './style.scss'
import HeaderTabs from './header-tabs'
import Header from './header'
import ColunasBolao from '../ColunasBolao'
import currencyFormat from '../../helpers/currencyFormat'

import {
  clearFunction, handleChange, loadMore, search, hasBet,
} from './helper'
import Body from './body'

const _icon = {
  winning,
  waiting,
  'no-winner': noWinner,
  cancelled,
  hidden,
}

const _result = {
  winning: 'Prêmio do Grupo',
  waiting: 'Aguardando sorteio',
  'no-winner': 'Bolão não premiado',
  cancelled: 'Bolão cancelado',
  hidden: 'Bolão reembolsado',
}

export default function JackpotModal(props) {
  const lines = []
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(false)
  const [finished, isFinished] = useState(false)
  const [gamesOpen, isGamesOpen] = useState(false)
  const [pagination, setPagination] = useState(false)

  useEffect(() => {
    const asyncFunctions = async () => {
      await setLoading(true)
      const allJackpots = !props.jackpotId && await jackpotServices.getJackpots({
        sku: props.jackpotSku,
        ref: data.bet.ref,
      })
      await setData({
        historic: props.betId && await jackpotServices.getHistoric(props.betId),
        bet: props.betId && await BettingsService.viewBet(props.betId),
        games: props.betId && await BettingsService.getBetGames(props.betId),
        jackpot: props.jackpotId
          ? await jackpotServices.viewJackpot(props.jackpotId)
          : await setData({ ...data, jackpot: await jackpotServices.viewJackpot(allJackpots[0].id).data })
        ,
      })

      await setLoading(false)
    }
    asyncFunctions()
  }, [])
  const { open, handleModal } = props
  return (
    <Modal
      open={open}
      onClose={handleModal}
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
      closeAfterTransition
      className="jackpot-modal"
      aria-labelledby="modal-title"
      aria-describedby="modal-body"
    >
      <Fade in={open}>
        <div className="modal-box">
          {/* HEADER */}
          <Header data={data} handleModal={handleModal} />

          {/* BODY */}
          <Body isResult={props.isResult} data={data} loading={loading} />
        </div>
      </Fade>
    </Modal>
  )
}
