/* eslint-disable max-len */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable linebreak-style */
import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';

import { history } from '../../helpers/history';
import Button from '../../components/Button';

import concourseService from '../../services/concourses';
import jackpotService from '../../services/jackpots';

import CouncourseCard from '../../components/ConcourseCard';

import './style.scss';

class Jackpots extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      concourses: [],
      jackpots: {},
      filter: {},
      pagination: {},

      loading: false,
      finished: true,
    };
  }

  updateType(type) {
    const { types } = this.state;
    types[type] ? types[type] += 1 : types[type] = 1;
    this.setState({
      ...this.state, types,
    });
  }

  async componentDidMount() {
    const concourses = await concourseService.getActiveConcourses();
    const jackpots = await jackpotService.getJackpots({ status: 'production' });

    this.setState({ ...this.setState, concourses: concourses.result, jackpots: jackpots.data.pagination });
  }

  render() {
    const types = {};

    return (
      <>
        <div className="cards-title">
          Participe agora de um dos nossos bolões
        </div>
        <div className="jackpot-totals">
          <strong>{this.state.jackpots.total}</strong>
          {' '}
          bolões encontrados
        </div>
        <div className="cards">
          <NavLink to="/boloes/"><CouncourseCard concourse={{ type: 'all', name: 'Todos bolões' }} /></NavLink>
          {this.state.concourses ? this.state.concourses.map((item, index) => {
            // atualiza os tipos de game para ter as variações
            types[item.type_game.slug] ? types[item.type_game.slug] += 1 : types[item.type_game.slug] = 1;

            return (
              <CouncourseCard
                key={index}
                concourse={{
                  type: item.type_game.slug,
	                name: item.name,
                  number: item.number,
                  drawDate: item.date_draw,
                  prize: item.premium_estimate,
                  type_number: types[item.type_game.slug],
                  concourse_id: item.id,
                  ...item,
                }}
              />
            );
          }) : ''}
        </div>
        <div className="buy-credits">
          <span>Compre créditos e jogue agora!</span>
          <Button color="lemon" action={(event) => history.push('/comprar-creditos')} label="Comprar Créditos" />
        </div>
      </>
    );
  }
}

export default Jackpots;
