import api from './api';

const terms = {
    async getTerms() {
        try {
            const response = await api.get('/show-term');
            return response.data.data;
        }
        catch(error) {
            console.log(error);
            return false;
        }
    },
    async acceptTerms() {
        try {
            const response = await api.post('/accept-term');

            return response;
            
        }
        catch(error) {
            return error.response;
        }
    }
}
export default terms;