/* eslint-disable linebreak-style */
/* eslint-disable keyword-spacing */
import Axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;
const publicRequest = Axios.create({ baseURL: BASE_URL });

const concourses = {
  async getEstimatedPrizes() {
    try {
      const { data } = await publicRequest.get('/campaigns/concourses/estimated-prizes');
      return data.data;
    } catch(error) {
      console.log(error);
      return false;
    }
  },
  async getActiveConcourses() {
    try {
      const { data } = await publicRequest.get('/campaigns/concourses/actives?target_channel=web_site');
      return data.data;
    } catch(error) {
      console.log(error);
      return false;
    }
  },
};
export default concourses;
