import { types } from './types';

const initialState = {
  key: null,
  items: [],
  credits: {},
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.ADD_TO_CART:
      return {
        ...state,
        expireCartFeedback: false,
        items: action?.content,
        ...action,
      };

    case types.UPDATE_EXPIRY:
      return { ...state, expiry_seconds_at: action.expiry_seconds_at };

    case types.ADD_CREDITS:
      return { ...state, credits: action.credits };

    case types.EXPIRE_CART_FEEDBACK:
      return { ...state, expireCartFeedback: action.value };

    case types.REMOVE_TO_CART:
      return { ...state, items: state.items.filter((item) => item !== action.item), ...action };

    case types.UPDATE_CART:
      return {
        ...state,
        items: action?.content,
        ...action,
      };

    case types.CLEAR_CART:
      localStorage.removeItem('cartKey');
      return {};

    case types.CLEAR_CART_CREDITS:
      return { ...state, credits: {} };

    case types.SET_KEY:
      return { ...state, key: action.key };

    default:
      return state;
  }
};
export default reducer;
