import React from 'react';

import About    from '../../sections/About';
import Reviews  from '../../sections/Reviews';
import LetsPlay from '../../sections/LetsPlay';

import registerIcon from '../../assets/images/icons/grande-cadastro.svg';
import jackpotIcon  from '../../assets/images/icons/grande-bolao.svg';
import paymentIcon  from '../../assets/images/icons/grande-pagamento.svg';
import successIcon  from '../../assets/images/icons/grande-cadastro-sucesso.svg';

import './style.scss';

class Home extends React.Component {
    
    render() {
        return (
            <main id="how-work">
                
                <section id="content">
                    <div className="container">
                        
                        <div className="row">
                            <h1>Como funciona o Unindo Sonhos</h1>
                        </div>
                        
                        <div className="row">

                            <article>
                                <figure>
                                    <img src={ registerIcon } alt=""/>
                                </figure>
                                <div className="content">
                                    <h2>Faça o seu cadastro ou efetue o login.</h2>
                                    <p>
                                        Fazer parte da Unindo Sonhos é muito fácil, basta realizar 
                                        um breve cadastro em nossa plataforma fornecendo alguns dados 
                                        para que possa realizar a compra de cotas dos bolões de forma 
                                        rápida e segura. O cadastro é gratuito, você só paga pelas cotas 
                                        dos bolões que pretende participar.
                                    </p>
                                    <p>
                                        Comece agora e clique no link Criar conta no topo do site e siga 
                                        as instruções.
                                    </p>
                                </div>
                            </article>
                            
                            <article>
                                <figure>
                                    <img src={ jackpotIcon } alt=""/>
                                </figure>
                                <div className="content">
                                    <h2>Escolha um ou mais bolões.</h2>
                                    <p>
                                        Dentro da plataforma Unindo Sonhos veiculamos bolões de diversas loterias 
                                        e cada bolão pode ter um número específico de cotas disponíveis para compra. 
                                        Você pode comprar quantas cotas quiser de um mesmo bolão ou até mesmo de 
                                        diversos bolões e processar tudo no mesmo carrinho de compras.
                                    </p>
                                </div>
                            </article>

                            <article>
                                <figure>
                                    <img src={ paymentIcon } alt=""/>
                                </figure>
                                <div className="content">
                                    <h2>Escolha a forma de pagamento.</h2>
                                    <p>Ao processar sua compra, disponibilizamos diversos métodos de pagamentos: Crédito na carteira, cartão de crédito e PIX. A forma de pagamento via boleto só está disponível para compra de crédito na carteira. O valor pago será compensado em até 48h (prazo estipulado pelos bancos).</p>
                                </div>
                            </article>

                            <article>
                                <figure>
                                    <img src={ successIcon } alt=""/>
                                </figure>
                                <div className="content">
                                    <h2>Agora é só torcer.</h2>
                                    <p>No momento em que as compras das cotas dos bolões forem realizadas e reconhecidas em nosso sistema, será necessário apenas aguardar a data do sorteio e torcer muito ! A conferência dos números, rateio e premiação são realizadas automaticamente pelo nosso sistema logo após o sorteio. Então, se der tudo certo, você poderá sacar seu prêmio através da carteira do usuário ou investir novamente em novos bolões.</p>
                                </div>
                            </article>

                        </div>

                    </div>
                </section>
                
                <About />
                <Reviews/>
                <LetsPlay />

            </main>
        );
    }
}
export default Home;