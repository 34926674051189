import React from 'react';
import Button from '../../components/Button';
import { history } from '../../helpers/history';

import paulinha from '../../assets/images/paulinha.png';
import './style.scss';

class SectionAbout extends React.Component {

    render() {
        return (<section id="section-about">
            <div className="container">
                <div className="row">
                    <figure>
                        <img src={ paulinha } alt="Foto da Paulinha, responsável pelo Unindo Sonhos."/>
                    </figure>
                    <div className="text">
                        <h1>Unindo Sonhos</h1>
                        <p>A Unindo sonhos tem o propósito de ajudar pessoas a realizar metas, sonhos e objetivos e através dos nossos jogos, mostramos que é muito possível chegar à realidade.</p>
                        <p>Disponibilizamos a melhor plataforma de jogos on-line para que você sinta-se seguro e tenha toda comodidade de apostar sem sair de casa e de qualquer lugar do mundo!</p>
                        <strong>Seja bem -vindo a Unindo Sonhos e Boa Sorte !</strong>
                        <Button type="button" label="Saiba mais" color="invert" action={ event => history.push('/quem-somos') }/>
                    </div>
                </div>
            </div>
        </section>)
    }
}

export default SectionAbout;