/* eslint-disable react/prop-types */
import React from 'react';

export function PixIcon({ isSelected }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 38.32 38">
      <g transform="translate(-535.433 -26.554)">
        <path
          style={{ fill: isSelected ? '#FFF' : '#209869' }}
          d="M573.623,78.544a5.6,5.6,0,0,1-3.981-1.648l-5.748-5.748a1.092,1.092,0,0,0-1.51,0l-5.769,5.769a5.6,5.6,0,0,1-3.981,1.648H551.5l7.28,7.279a5.822,5.822,0,0,0,8.233,0l7.3-7.3Z"
          transform="translate(-8.145 -22.995)"
        />
        <path
          style={{ fill: isSelected ? '#FFF' : '#209869' }}
          d="M552.636,35.539a5.6,5.6,0,0,1,3.981,1.648l5.769,5.77a1.069,1.069,0,0,0,1.51,0l5.748-5.748a5.6,5.6,0,0,1,3.981-1.648h.692l-7.3-7.3a5.822,5.822,0,0,0-8.233,0L551.5,35.539Z"
          transform="translate(-8.145 0)"
        />
        <path
          style={{ fill: isSelected ? '#FFF' : '#209869' }}
          d="M572.209,52.733,567.8,48.321a.84.84,0,0,1-.313.063h-2.006a3.964,3.964,0,0,0-2.785,1.154l-5.747,5.748a2.762,2.762,0,0,1-3.9,0l-5.769-5.769a3.963,3.963,0,0,0-2.785-1.154h-2.466a.836.836,0,0,1-.3-.06L537.3,52.733a5.821,5.821,0,0,0,0,8.233l4.429,4.429a.838.838,0,0,1,.3-.06h2.466a3.964,3.964,0,0,0,2.785-1.154l5.769-5.769a2.826,2.826,0,0,1,3.9,0l5.747,5.747a3.964,3.964,0,0,0,2.785,1.154h2.006a.839.839,0,0,1,.313.063l4.412-4.412a5.822,5.822,0,0,0,0-8.233"
          transform="translate(-0.16 -11.295)"
        />
      </g>
    </svg>
  );
}

export function BoletoIcon({ isSelected }) {
  return (
    <svg width="25" height="25" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 0H2V22H0V0ZM10 0H12V20H10V0ZM4 0H8V20H4V0ZM14 0H18V20H14V0ZM20 0H24V20H20V0ZM26 0H28V22H26V0Z"
        style={{ fill: isSelected ? '#FFF' : '#209869' }}
      />
    </svg>
  );
}

export function CardIcon({ color = '' }) {
  return (
    <svg
      width={25}
      height={25}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke={color || 'currentColor'}
      className="w-6 h-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z"
      />
    </svg>
  );
}
