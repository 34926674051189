/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable eqeqeq */
/* eslint-disable react/prop-types */
/* eslint-disable no-return-assign */
/* eslint-disable no-case-declarations */
/* eslint-disable react/jsx-key */
import { DatePicker, Input, InputNumber } from 'antd';
import { Controller } from 'react-hook-form';
import Select from 'react-select';
import MaskedInput from 'antd-mask-input';
import React, { useEffect } from 'react';
import './style.scss';

export const TYPES_DATA = {
  MASK: 'mask',
  SELECT: 'select',
  PERCENT: 'percent',
  NUMBER: 'number',
  DATE: 'date',
  MONEY: 'money',
  OTHERS_EMPLOYEE: 'others-employee',
  PASSWORD: 'password',
};

const locale = 'pt-BR';

const currencyFormatter = () => (value) => new Intl.NumberFormat(locale, {
  style: 'currency',
  currency: 'BRL',
}).formatToParts(value).map(
  (p) => (p.type != 'literal' && p.type != 'currency' ? p.value : ''),
).join('');

const currencyParser = (val) => {
  try {
    // for when the input gets clears
    if (typeof val === 'string' && !val.length) {
      val = '0.0';
    }

    // detecting and parsing between comma and dot
    const group = new Intl.NumberFormat(locale).format(1111).replace(/1/g, '');
    const decimal = new Intl.NumberFormat(locale).format(1.1).replace(/1/g, '');
    let reversedVal = val.replace(new RegExp(`\\${group}`, 'g'), '');
    reversedVal = reversedVal.replace(new RegExp(`\\${decimal}`, 'g'), '.');
    //  => 1232.21 €

    // removing everything except the digits and dot
    reversedVal = reversedVal.replace(/[^0-9.]/g, '');
    //  => 1232.21

    // appending digits properly
    const digitsAfterDecimalCount = (reversedVal.split('.')[1] || []).length;
    const needsDigitsAppended = digitsAfterDecimalCount > 2;

    if (needsDigitsAppended) {
      reversedVal *= 10 ** (digitsAfterDecimalCount - 2);
    }

    return Number.isNaN(reversedVal) ? 0 : reversedVal;
  } catch (error) {
    console.error(error);
  }
};

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? '#fff' : '#23262F',
    fontSize: 14,
    background: state.isSelected ? '#209869' : '#fff',
  }),
  control: (provided, state) => ({
    ...provided,
    transition: 'border 0.2s',
    '&:hover': {
      borderColor: '#209869',
    },
    fontSize: 14,
    border: state.isFocused ? '1px solid #209869' : '1px solid #E6E8EC',
    boxShadow: '0 0 0 0 transparent',
    borderRadius: '8px',
  }),
};

const onSelectOptionChange = (customChange, inputValue, action, prevInputValue) => {
  if (action === 'select-option') {
    customChange(inputValue);
    return inputValue;
  }
  return prevInputValue;
};

export default function Form({
  className = '', id = 'form', errors, control, fields, handleSubmit = null,
}) {
  useEffect(() => {
    document.querySelector('.ant-input-number-group-addon')?.classList.add('group-focus-within:!border-primary');
  }, []);
  function getFieldByType(field, type, fieldValues, error = null) {
    switch (type) {
      case TYPES_DATA.SELECT:
        return (
          <Select
            {...field}
            isDisabled={fieldValues.disabled}
            defaultValue={fieldValues.defaultValue}
            styles={customStyles}
            placeholder="Selecione uma opção"
            id={fieldValues.id}
            key={fieldValues.id}
            name={fieldValues.id}
            loading={fieldValues.loading}
            options={fieldValues.options}
            onChange={fieldValues.onChange ? (inputValue, { action, prevInputValue }) => onSelectOptionChange(fieldValues.onChange, inputValue, action, prevInputValue) : null}
          />
        );
      case TYPES_DATA.PERCENT:
        return (
          <Input
            {...field}
            type="number"
            disabled={fieldValues.disabled}
            autoComplete="false"
            defaultValue={fieldValues.defaultValue}
            id={fieldValues.id}
            key={fieldValues.id}
            name={fieldValues.id}
            placeholder={fieldValues.placeholder}
            className="antd-input-text"
            status={error ? 'error' : ''}
          />
        );
      case TYPES_DATA.MASK:
        return (
          <MaskedInput
            {...field}
            mask={fieldValues.mask}
            inputMode={fieldValues.keyboardType || 'text'}
            disabled={fieldValues.disabled}
            id={fieldValues.id}
            key={fieldValues.id}
            name={fieldValues.id}
            defaultValue={fieldValues.defaultValue}
            value={fieldValues.defaultValue ? fieldValues.defaultValue : field.value}
            placeholder={fieldValues.placeholder}
            className="antd-input-text"
            status={error ? 'error' : ''}
          />
        );
      case TYPES_DATA.DATE:
        return (
          <DatePicker
            {...field}
            format="DD/MM/YYYY"
            defaultValue={fieldValues.defaultValue}
            autoComplete="false"
            disabled={fieldValues.disabled}
            id={fieldValues.id}
            key={fieldValues.id}
            name={fieldValues.id}
            placeholder={fieldValues.placeholder}
            className="antd-input-text"
            status={error ? 'error' : ''}
          />
        );
      case TYPES_DATA.MONEY:
        return (
          <div className="flex items-center w-full group">
            <InputNumber
              {...field}
              formatter={currencyFormatter(field.value)}
              parser={currencyParser}
              inputMode="numeric"
              disabled={fieldValues.disabled}
              autoComplete="false"
              defaultValue={fieldValues.defaultValue}
              id={fieldValues.id}
              key={fieldValues.id}
              name={fieldValues.id}
              placeholder={fieldValues.placeholder}
              className="!w-full group !p-0 transition"
              status={error ? 'error' : ''}
              addonBefore="R$"
            />
          </div>
        );
      case TYPES_DATA.NUMBER:
        return (
          <Input
            {...field}
            type="number"
            autoComplete="false"
            defaultValue={fieldValues.defaultValue}
            disabled={fieldValues.disabled}
            id={fieldValues.id}
            width={fieldValues.width}
            inputMode="numeric"
            key={fieldValues.id}
            name={fieldValues.id}
            placeholder={fieldValues.placeholder}
            className="antd-input-text"
            status={error ? 'error' : ''}
          />
        );
      case TYPES_DATA.PASSWORD:
        return (
          <Input.Password
            {...field}
            autoComplete="false"
            disabled={fieldValues.disabled}
            defaultValue={fieldValues.defaultValue}
            id={fieldValues.id}
            key={fieldValues.id}
            name={fieldValues.id}
            placeholder={fieldValues.placeholder}
            className="antd-input-text"
            status={error ? 'error' : ''}
          />
        );
      default:
        return (
          <Input
            {...field}
            disabled={fieldValues.disabled}
            autoComplete="false"
            defaultValue={fieldValues.defaultValue}
            id={fieldValues.id}
            key={fieldValues.id}
            name={fieldValues.id}
            placeholder={fieldValues.placeholder}
            className="antd-input-text"
            status={error ? 'error' : ''}
          />
        );
    }
  }

  return (
    <form id={id} className={`form-component antd ${className || ''}`} onSubmit={handleSubmit}>
      {fields.map((fieldValues) => (
        <div key={fieldValues.id} className={`flex flex-col ${fieldValues.className}`} id={fieldValues.id}>
          <Controller
            key={fieldValues.id}
            name={fieldValues.id}
            control={control}
            render={({ field }) => (
              <div className={`flex flex-col gap-y-2 mb-2 ${fieldValues.customClass}`}>
                {fieldValues.label && <label htmlFor={fieldValues.id} className="font-bold uppercase text-2xs text-neutral-3">{fieldValues.label}</label>}
                {getFieldByType(field, fieldValues.type, fieldValues, errors[fieldValues.id])}
                <p className="label-error form-antd">{errors[fieldValues.id] && errors[fieldValues.id].message}</p>
              </div>
            )}
          />
        </div>
      ))}
    </form>
  );
}
