/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-lone-blocks */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Form, { TYPES_DATA } from '../../../components/Form';
import Button from '../../../components/Button';
import { checkResponse } from '../../../helpers/checkResponse';
import { getAllStates } from '../../../helpers/getBrazilStates';
import getCitiesByState from '../../../helpers/getCitiesByState';
import account from '../../../services/account';
import { handleCheckoutStep } from '../index';

export default function AddressStep({
  setCheckoutData, setStep, setLoading, loading,
}) {
  const [addressDisabled, setAddressDisabled] = useState(true);

  const schema = yup.object({
    zipcode: yup.string().required('Campo obrigatório.'),
    address: yup.string().required('Campo obrigatório.'),
    number: yup.string().required('Campo obrigatório.'),
    district: yup.string().required('Campo obrigatório.'),
    state: yup.object().required('Campo obrigatório.').typeError('Você deve selecionar um estado.'),
    city: yup.object().required('Campo obrigatório.').typeError('Você deve selecionar uma cidade.'),
    country: yup.string(),
  }).required();

  const {
    control, clearErrors, getValues, handleSubmit, watch, setError, setValue, formState: { isDirty, errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [isLoading, setIsLoading] = useState(false);
  const [allCitiesOfState, setAllCitiesOfState] = useState({});
  const [allStatesOfBrazil, setAllStatesOfBrazil] = useState({});

  async function getStates() {
    const states = await getAllStates();

    states.sort((a, b) => {
      const first = a.nome.toUpperCase();
      const second = b.nome.toUpperCase();

      let comparison = 0;
      if (first > second) comparison = 1;
      else if (first < second) comparison = -1;

      return comparison;
    });

    const formattedOptions = await states.map((state) => ({
      label: state.sigla,
      value: state.sigla,
    }));

    setAllStatesOfBrazil(formattedOptions);
    return formattedOptions;
  }

  const getStatesOfBrazil = async () => {
    await getStates();
  };

  const getAllCities = async () => {
    const selectedState = getValues('state')?.value;
    if (selectedState) {
      const formattedCities = await getCitiesByState(selectedState).map((city) => ({
        label: city,
        value: city,
      }));

      setAllCitiesOfState(formattedCities);
    }
  };

  async function onSubmit() {
    setIsLoading(true);
    await account.updateUserAddress({
      zipcode: getValues('zipcode').replaceAll('-', ''),
      address: getValues('address'),
      number: getValues('number'),
      district: getValues('district'),
      state: getValues('state').value,
      city: getValues('city').value,
      country: 'BRA',
    }).then((response) => {
      if (response.data) {
	      handleCheckoutStep(setCheckoutData, setStep, setLoading);
      }
    });
    setIsLoading(false);
  }

  useEffect(() => {
    const zipcode = getValues('zipcode')?.replaceAll('-', '').replaceAll('_', '');
    zipcode?.length < 8 && setAddressDisabled(true);
    if (zipcode?.length === 8 && isDirty) {
      setAddressDisabled(true);
      account.getAddressByZipcode(zipcode).then(async (response) => {
        clearErrors('zipcode');
        if (response?.erro) {
          setError('zipcode', { type: 'value', message: 'CEP não encontrado.' });
          return;
        }

        if (!response?.erro) {
          await setValue('address', response.logradouro);
          await setValue('district', response.bairro);
          await setValue('state', {
            label: response.uf,
            value: response.uf,
          });
          await setValue('city', {
            label: response.localidade,
            value: response.localidade,
          });
          await getAllCities();
        }
        setAddressDisabled(false);
      });
    }
  }, [watch('zipcode')]);

  useEffect(() => {
    getStatesOfBrazil();
  }, []);

  function handleChangeState(inputValue) {
    setValue('state', inputValue);
    setValue('city', '');
    getAllCities();
  }

  function handleChangeCity(inputValue) {
    setValue('city', inputValue);
  }

  return (
    <>
      {!loading && (
        <section id="checkout-step-address" className={`${loading ? 'loading' : ''}`}>
          <h1 className="title">Endereço</h1>
          <Form
            errors={errors}
            control={control}
            fields={[
              {
                label: 'CEP',
                name: 'zipcode',
                id: 'zipcode',
                keyboardType: 'numeric',
                type: TYPES_DATA.MASK,
                mask: '00000-000',
              },
              {
                label: 'RUA',
                name: 'address',
                id: 'address',
                disabled: addressDisabled,
              },
              {
                label: 'NÚMERO',
                name: 'number',
                type: TYPES_DATA.NUMBER,
                id: 'number',
                disabled: addressDisabled,
              },
              {
                label: 'BAIRRO',
                name: 'district',
                id: 'district',
                disabled: addressDisabled,
              },
              {
                label: 'ESTADO',
                name: 'state',
                id: 'state',
                type: TYPES_DATA.SELECT,
                options: allStatesOfBrazil,
                loading: !allStatesOfBrazil,
                onChange: (inputValue) => handleChangeState(inputValue),
                disabled: addressDisabled,
              },
              {
                label: 'CIDADE',
                name: 'city',
                id: 'city',
                type: TYPES_DATA.SELECT,
                options: allCitiesOfState,
                loading: !allCitiesOfState,
                disabled: !allCitiesOfState || addressDisabled,
                onChange: (inputValue) => handleChangeCity(inputValue),
              },
              {
                label: 'PAÍS',
                name: 'country',
                id: 'country',
                disabled: true,
                value: 'Brasil',
                defaultValue: 'Brasil',
              },
            ]}
            handleSubmit={handleSubmit(onSubmit)}
          />
          <Button form="form" type="submit" width="100%" loading={isLoading} label="Cadastrar endereço" color="green" />
        </section>
      )}
    </>
  );
}
