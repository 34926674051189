import moment from 'moment';
import React from 'react';
import accessTime from '../../assets/images/icon-access-time.svg';

class JackpotTimeLeft extends React.Component {
    state = {
        timeLeft:{}
    };

    componentDidMount() {
        this.interval = setInterval(() => {
            const { timeTillDate, timeFormat } = this.props;

            const end       =       moment(timeTillDate,timeFormat);
            const now       =       moment();
            const difference =       (end - now);
            let timeLeft = {};

            if (difference > 0) {
                timeLeft = {
                  days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                  hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                  minutes: Math.floor((difference / 1000 / 60) % 60),
                  seconds: Math.floor((difference / 1000) % 60)
                };
            }
            this.setState({timeLeft });
        }, 1000);
    }

    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    render() {
        const { days, hours, minutes, seconds } = this.state.timeLeft;
        const { clock } = this.props.clock

        return (
            <span>
                {clock ?  <img src={accessTime} alt="Ícone de relógio"/> : ''}
                {days       ?   days    +   "d ":""}
                {hours      ?   hours   +   "h ":""}
                {minutes    ?   minutes +   "m ":""}
                {seconds    ?   seconds +   "s ":""}
            </span>
        );
    }
}

export default JackpotTimeLeft