import React, { useEffect } from 'react';
import Button from '../Button';
import { history } from '../../helpers/history';
import EmptyCartIcon from '../../assets/images/empty-cart.svg';

export default function EmptyCart() {
  useEffect(() => {
    setTimeout(() => {
      history.push('/boloes');
    }, 3000);
  }, []);

  return (
    <div className="empty-cart">
      <img src={EmptyCartIcon} alt="Carrinho vazio" />
      <span className="empty-text">Seu carrinho está vazio.</span>
      <span className="empty-description">Você será redirecionado para a página de bolões em alguns segundos...</span>
      <Button color="green" label="Ver bolões" action={() => history.push('/boloes')} />
    </div>
  );
}
