/* eslint-disable react/no-array-index-key */
/* eslint-disable react/react-in-jsx-scope */
import './style.scss'
import { CircularProgress, withStyles } from '@material-ui/core'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

import { useState } from 'react'
import { isMobile } from 'react-device-detect'
import TabContent from './content'
import { hasBetFunction, hasBetActives } from './helper'

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>
          {children}
        </div>
      )}
    </div>
  )
}

export default function Body({ isResult, data, loading }) {
  const [value, setValue] = useState(isResult ? 0 : 1)

  const tabs = [
    {
      title: isMobile ? 'Informações' : 'Informações do Bolão',
      slug: 'jackpot-infos',
      className: 'first-tab',
      active: hasBetActives(data),
    //   order: data && hasBetFunction(data) ? 1 : 2,
    },
    {
      title: isMobile ? 'Jogos' : 'Jogos desse Bolão',
      slug: 'jackpot-games',
      active: hasBetActives(data),
      className: 'second-tab',
    //   order: data && hasBetFunction(data) ? 2 : 1,
    },
    {
      title: isMobile ? 'Suas cotas' : 'Suas cotas neste Bolão',
      slug: 'jackpot-cotas',
      active: data && hasBetFunction(data),
      className: 'third-tab',
    //   order: 3,
    },
  ]
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const CustomTab = withStyles({
    root: {
      textTransform: 'capitalize',
      fontSize: 16,
      fontWeight: 400,
      color: '#2a2b41',
    },
  })(Tab)
  return (
    <div className="modal-body">
      <div className="top">
        {!loading && (
          <Tabs
            value={value}
            centered
            scrollButtons
            allowScrollButtonsMobile
            className={`tab-list ${isResult ? '' : 'tab-reverse'}`}
            onChange={handleChange}
          >
            {tabs.map((tab, index) => tab.active && <CustomTab className={`tab-item ${(value === index) && 'active'}`} label={tab.title} />)}
          </Tabs>
        )}
        {tabs.map((tab, index) => (
          <TabPanel value={value} index={index} key={index} className={`${tab.className} modal-tab-${index} ${tab.slug}`}>
            <TabContent isResult={isResult} data={data} tabData={tab} key={index} loading={loading} />
          </TabPanel>
        )).sort((a, b) => a[4] > b[4])}
        {loading && (
          <span className="loading">
            <CircularProgress />
          </span>
        )}
      </div>
    </div>
  )
}
