import Axios from 'axios';
import api from './api';
import getLoggedUserInfo from '../helpers/getLoggedUserInfo';

const userInfo = getLoggedUserInfo(true);

const onboardRequest = Axios.create({
  baseURL: process.env.REACT_APP_ON_BOARDING_API,
  headers: {
    Authorization: `Bearer ${userInfo?.token}`,
  },
});

const account = {
  async getAddressByZipcode(zipcode) {
    const { data } = await api.get(`https://viacep.com.br/ws/${zipcode}/json/`);
    return data;
  },
  async getOnboardProfile() {
    try {
      const { data } = await onboardRequest.get('/user/profile')
      return {
        data: data.data,
      }
    } catch (error) {
      return error
    }
  },
  async getMe() {
    try {
      const { data } = await api.get('/me');
      return data;
    } catch (error) {
      return false;
    }
  },
  async handleLogout(token) {
    try {
      const { data } = await api.post('/logout', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return data;
    } catch (error) {
      return false;
    }
  },
  async updateUserData(userData) {
    try {
      const { data } = await api.post('/customer/edit-account', userData);
      return data;
    } catch (error) {
      return false;
    }
  },
  async updateUserAddress(userAddress) {
    try {
      const { data } = await api.post('/customer/edit-address', userAddress);
      return data;
    } catch (error) {
      return false;
    }
  },
  async getBankAccounts() {
    try {
      const { data } = await api.get('/customer/bank-accounts');
      return data;
    } catch (error) {
      return false;
    }
  },
  async addBankAccount(account) {
    try {
      const { data } = await api.post('/customer/bank-accounts/create', account);
      return data;
    } catch (error) {
      console.log(error);
      return false;
    }
  },
  async removeBankAccount(account) {
    try {
      const { data } = await api.delete(`/customer/bank-accounts/${account.id}`);
      return data;
    } catch (error) {
      console.log(error);
      return false;
    }
  },
  async getMyOrders(id = null) {
    try {
      const _endPoint = (id) ? `/customer/orders/view/${id}` : '/customer/orders';
      const { data } = await api.post(_endPoint);
      return data;
    } catch (error) {
      console.log(error);
      return false;
    }
  },
  async updateBankAccount(account) {
    try {
      const _input = { holder: account.holder, is_default: true };
      const { data } = await api.put(`/customer/bank-accounts/${account.id}`, _input);
      return data;
    } catch (error) {
      console.log(error);
    }
  },

  async getCards(params) {
    try {
      const { data } = await onboardRequest.get('/user-vaults?', {
        params,
      });
      return {
        data: data.data,
      };
    } catch (error) {
      console.error(error);
      return { error: true };
    }
  },

  async updateCard(cardID) {
    try {
      const { data } = await onboardRequest.put(`/user-vaults/${cardID}/default`);
      return {
        data: data.data,
      };
    } catch (error) {
      console.error(error);
      return error;
    }
  },
};
export default account;
