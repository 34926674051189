import { types } from './types';
const initialState  = { 
    feedback: { show: false, severity: "", message: "" },
    openLogin:   false,
    openCart:    false,
    mobileMenu:  false,
    needAcceptTerm: false,
    sendedPin:   false // Um artifício técnico para evitar que o e-mail com o pin seja enviado 2x.
};

const reducer = (state = initialState, action = { }) => { 
    switch(action.type) {
        case types.SET_FEEDBACK:
            return { ...state, feedback: action.feedback };
        case types.OPEN_LOGIN:
            return { ...state, openLogin: !state.openLogin };
        case types.OPEN_CART:
            return { ...state, openCart: !state.openCart };
        case types.OPEN_MOBILE_MENU:
            return { ...state, mobileMenu: !state.mobileMenu };
        case types.NEED_ACCEPT_TERM:
            return { ...state, needAcceptTerm: action.needAcceptTerm };
        case types.SENDED_PIN:
            return { ...state, sendedPin: !state.sendedPin };
        default:
            return state;
    }
}
export default reducer;