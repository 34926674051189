/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import { composeWithDevTools } from 'redux-devtools-extension';

import { createStore, applyMiddleware, combineReducers } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';

import * as reducers from './store/reducers';
import App from './App';

const store = createStore(combineReducers(reducers), composeWithDevTools(applyMiddleware(thunk)));

if (process.env.NODE_ENV && process.env.NODE_ENV !== 'development') {
  const tagManagerArgs = { gtmId: 'GTM-PPXZBG45' };
  TagManager.initialize(tagManagerArgs);
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);

// REACT 18 INIT -

// const root = createRoot(document.getElementById('root'));

// root.render(
//   <Provider store={store}>
//     <App />
//   </Provider>,
// );
