import api   from './api';

const statistics = {
    async getPrizes() {
        try {
            const { data } = await api.get('/statistics/prizes');
            return data.data;
        }
        catch(error) {
            console.log(error);
            return false;
        }
    }
}
export default statistics;