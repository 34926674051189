/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
/* eslint-disable prefer-const */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-return-assign */
/* eslint-disable react/react-in-jsx-scope */
import SearchIcon from '@material-ui/icons/Search'
import CloseIcon from '@material-ui/icons/Close'
import { useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import { clearFunction, handleChange, search } from './helper'
import currencyFormat from '../../helpers/currencyFormat'
import ColunasBolao from '../ColunasBolao'
import GameColumns from '../GameColumns'
import { resultIcon } from '../../constants/result-status'

export default function JackpotGames({ isResult, data }) {
  const [terms, setTerms] = useState('')
  const [clear, isClear] = useState(false)
  const allGamesResult = data.games?.result
  const allGamesJackpot = data.jackpot?.games
  const gameCount = allGamesResult ? allGamesResult.length : allGamesJackpot.length
  let lines = []
  return (
    <>
      <div className="search-bar">
        <strong>
          {`${gameCount} apostas`}
        </strong>
        <form
          className="form-group"
          onSubmit={search}
        >
          <input
            type="text"
            className="form-control"
            value={terms}
            placeholder="Busque pelo nº da aposta"
            onChange={handleChange}
          />
          <button type="submit" className="submit">
            <SearchIcon />
          </button>
          {clear ? (
            <button type="button" className="clear" onClick={clearFunction}>
              <CloseIcon className="icon" />
            </button>
          ) : null}
        </form>
      </div>
      <div>
        {allGamesResult
          ? (
            <ul>
              {allGamesResult.map((game, index) => {
                const isWinner = game.prize_result?.dezenas.status === 'winning'
                                || game.prize_result?.dezenas_segundo_sorteio.status === 'winning'
                                || game.prize_result?.complement.status === 'winning'
                  ? 'winning'
                  : ''
                return (
                  <CSSTransition
                    key={index}
                    timeout={{ enter: index * 100, exit: index * 100 }}
                    classNames="fade"
                  >
                    <li key={index} ref={(ref) => lines.push(ref)}>
                      <div className="game-counter">
                        {(isWinner || (game.prize_result && game.prize_result?.dezenas.status)) && isResult && (
                          <img
                            src={resultIcon[isWinner || (game.prize_result && game.prize_result?.dezenas.status)]}
                            alt=""
                            className="icon-size-mobile"
                          />
                        )}
                        <span className="id bold">{game.id}</span>
                      </div>
                      {game.prize_result ? (
                        <div className="award">
                          <div className="award-li">
                            <span>Prêmio do Grupo:</span>
                            <span className="bold">
                              {currencyFormat(game.prize_result.total)}
                            </span>
                          </div>
                          <div className="award-li">
                            <span>
                              Rateio
                              {data.bet
                                ? `(${data.bet.qnty_active
                                }/${data.jackpot.shares_quantity
                                })`
                                : ''}
                              :
                            </span>
                            <span className="bold">
                              {currencyFormat(game.prize_result.player)}
                            </span>
                          </div>
                        </div>
                      ) : (
                        ''
                      )}

                      <GameColumns game={game} isResult={isResult} type={data.jackpot.type_game.slug} />

                    </li>
                  </CSSTransition>
                )
              })}
            </ul>
          )
          : (
            <ul>
              {allGamesJackpot.map((game, index) => {
                const isWinner = game.prize_result?.dezenas.status === 'winning'
                || game.prize_result?.dezenas_segundo_sorteio.status === 'winning'
                || game.prize_result?.complement.status === 'winning'
                  ? 'winning'
                  : ''

                return (
                  <li key={index}>
                    <div className="game-counter">
                      {(isWinner || (game.prize_result && game.prize_result?.dezenas.status)) && (
                        <img
                          src={resultIcon[isWinner || (game.prize_result && game.prize_result?.dezenas.status)]}
                          alt=""
                          className="icon-size-mobile"
                        />
                      )}
                      <span className="id bold">{game.id}</span>
                    </div>
                    <GameColumns isResult={isResult} game={game} type={data.jackpot.type_game.slug} />
                  </li>
                )
              })}
            </ul>
          )}
        {!allGamesJackpot && !allGamesResult && <span>Nenhum jogo encontrado</span>}

      </div>
    </>
  )
}
