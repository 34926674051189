import React    from 'react';
import bigLogo  from '../../assets/images/logotipo-unindo-sonhos-small.png';

import './style.scss';

class JackpotModal extends React.Component {

    render() {        
        return(
            <div id="warning-modal">
                <div className="modal-box">

                    <div className="modal-body">
                        <img src={ bigLogo } alt=""/>
                        <h1>ATENÇÃO!</h1>
                        
                        <p>
                            O sistema de compra de créditos está indisponível no momento.
                            Estamos realizando uma manutenção programada e em breve esse
                            serviço estará disponível novamente.
                            <br/>Agradecemos pela sua compreensão.
                        </p>

                    </div>

                </div>
            </div>
        )
    }
}

export default JackpotModal;