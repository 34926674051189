import api from './api';

const contact = {
  async sendMail(payload) {
    try {
      const { data } = await api.post('/contact', {
        ...payload,
      });

      return data.data;
    } catch (error) {
      console.log(error);
      return false;
    }
  },
};
export default contact;
