/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-filename-extension */
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';

import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import Concourses from '../../sections/Concourses';
import About from '../../sections/About';
import TotalPrizes from '../../sections/TotalPrizes';
import HowWork from '../../sections/HowWork';
import Reviews from '../../sections/Reviews';
import LetsPlay from '../../sections/LetsPlay';

import bannerService from '../../services/banners';

import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';

import './style.scss';
import { history } from '../../helpers/history';

SwiperCore.use([Navigation, Pagination, Autoplay]);

export default function Home() {
  const [banners, setBanners] = useState([]);

  useEffect(() => {
    bannerService.getBanners().then(({ data }) => setBanners(data.result));
  }, []);

  function handleBannerClick(banner) {
    if (!banner.link) return;

    const bannerLink = banner.link;
    const siteOrigin = window.location.origin;
    const isSameOrigin = bannerLink.includes(siteOrigin);

    if (isSameOrigin) {
      const pageDestination = bannerLink.replace(siteOrigin, '');
      history.push(pageDestination);
    } else {
      window.open(bannerLink);
    }
  }

  return (
    <main id="home">
      <section id="banner">
        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          loop
          autoplay={{ delay: 5000 }}
          pagination={{ clickable: true }}
          navigation
        >
          {banners?.map((banner) => {
            const bannerImage = isMobile ? banner.image_mobile : banner.image_desktop;

            return (
              <SwiperSlide
                key={banner.title + bannerImage}
                onClick={() => handleBannerClick(banner)}
              >
                <figure>
                  <img src={bannerImage} alt={banner.title} />
                </figure>
              </SwiperSlide>
            );
          })}

        </Swiper>
      </section>

      {/* <Jackpots /> */}
      <Concourses />
      <About />
      <TotalPrizes />
      <HowWork />
      <Reviews />
      <LetsPlay />
    </main>
  );
}
