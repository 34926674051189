/* eslint-disable linebreak-style */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Route, Redirect } from 'react-router-dom';

import * as accountActions from '../../store/account/actions';

function PrivateMenu({ component: Component, ...rest }) {
  const redirect = (props) => {
    const storage = JSON.parse(localStorage.getItem('unindo_sonhos_data'));

    if (!storage && props.userData) { props.reset(); }

    return (<Redirect to={{ pathname: '/', state: { from: props.location } }} />);
  };

  return (
    <Route
      {...rest}
      render={
            (props) => {
              const storage = JSON.parse(localStorage.getItem('unindo_sonhos_data'));
              return (storage && storage.token)
                ? (<Component {...props} />)
                : redirect(rest);
            }
}
    />
  );
}
const mapStateToProps = (state) => ({ userData: state.accountReducer.userData });
const mapDispatchToProps = (dispatch) => bindActionCreators(accountActions, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(PrivateMenu);
