/* eslint-disable arrow-body-style */
/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-no-useless-fragment */
import './style.scss'
import React from 'react'
import moment from 'moment'
import GetAppIcon from '@material-ui/icons/GetApp'
import currencyFormat from '../../helpers/currencyFormat'
import { resultIcon, resultStatus } from '../../constants/result-status'
import ColunasBolao from '../ColunasBolao'

export default function JackpotInfos({ data }) {
  const isWinning = data.bet?.prize_result?.status === resultStatus.winning
  const hasData = (Object.keys(data).length > 0)
  const betStatus = resultStatus[data.bet?.prize_result.status]
  const betIcon = resultIcon[data.bet?.prize_result?.status]
  const betTotal = currencyFormat(data.bet?.prize_result?.total)
  const hasCaixaResult = (data.bet?.caixa_result)

  return (
    <>
      {hasData
            && (
              <>
                {data.bet?.prize_result.status !== 'hidden'
                && (
                  <>
                    {data.bet
                && (
                  <>
                    <ul className="list primary-list">
                      <li className="prize-result">
                        <span>
                          <img src={betIcon} alt="" className="icon-size" />
                          {betStatus}
                        </span>
                        <span className="bold">{betTotal}</span>
                      </li>
                      {isWinning && (
                        <li className="prize-result">
                          <span>
                            <img src={betIcon} alt="" className="icon-size" />
                            Seu Prêmio
                          </span>
                          <span className="bold">{betTotal}</span>
                        </li>
                      )}
                      {hasCaixaResult && (
                        <>
                          <li className="caixa-result">
                            <span>Números sorteados:</span>
                            {data.bet && data.games && <ColunasBolao caixaResult bet={data.bet} jackpot={data.jackpot} />}
                          </li>
                          {data.bet.caixa_result?.dezenas_segundo_sorteio
                            && (
                              <li className="caixa-result">
                                <span>Segundo sorteio:</span>
                                <li className="caixa-result" style={{ padding: 0 }}>
                                  {data.bet.caixa_result && data.bet.caixa_result?.dezenas_segundo_sorteio
                                    .split(' ')
                                    .map((item, index) => {
                                      return <span className="number" key={index}>{item}</span>
                                    })}
                                </li>
                              </li>
                            //   <>
                            //     <li className="caixa-result">
                            //       <span>Segundo sorteio:</span>
                            //       {data.bet.caixa_result?.dezenas_segundo_sorteio && data.bet.caixa_result?.dezenas_segundo_sorteio
                            //         .split(' ')
                            //         .map((item, index) => <span className="number" key={index}>{item}</span>)}
                            //     </li>
                            //   </>
                            )}
                        </>
                      )}

                    </ul>
                  </>
                )}
                    <ul className="list secondary-list">
                      <li className="draw-date">
                        <span>Sorteio: </span>
                        <span className="bold">
                          {moment(data.jackpot.draw_date, 'DD/MM/YYYY H:m:s').format(
                            'DD/MM/YYYY - H:mm',
                          )}
                        </span>
                      </li>
                      <li className="max-prize">
                        <span>Prêmio máximo:</span>
                        <span className="bold">{currencyFormat(data.jackpot.prize_amount)}</span>
                      </li>
                      <li className="sku">
                        <span>Concurso:</span>
                        <span className="bold">{data.jackpot.sku}</span>
                      </li>
                      <li className="prize-ref">
                        <span>Referência:</span>
                        <span className="bold">{data.jackpot.ref}</span>
                      </li>

                      {data.bet?.qnty && (
                        <li className="shares">
                          <span>Cotas:</span>
                          <span className="bold">
                            {`${data.bet.qnty_active}/${data.jackpot.shares_quantity}`}
                          </span>
                        </li>
                      )}
                    </ul>
                    {data.bet?.receipt && data.bet?.receipt[0]?.s3_url && (
                      <a href={data.bet.receipt[0].s3_url} target="_blank" className="button receipt-button" rel="noreferrer">
                        <GetAppIcon />
                        Comprovante do bolão
                      </a>
                    )}
                    <span className="bold">Resumo da aposta</span>
                    <div className="description" dangerouslySetInnerHTML={{ __html: data.jackpot.descript }} />
                  </>
                )}
              </>
            )}

    </>
  )
}
