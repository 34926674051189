/* eslint-disable react/react-in-jsx-scope */
import Timeline from '@material-ui/lab/Timeline'
import TimelineItem from '@material-ui/lab/TimelineItem'
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent'
import Typography from '@material-ui/core/Typography'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'
import TimelineConnector from '@material-ui/lab/TimelineConnector'
import TimelineContent from '@material-ui/lab/TimelineContent'
import TimelineDot from '@material-ui/lab/TimelineDot'
import './style.scss'

export default function CotasUsuario({ data }) {
  const historicData = data.historic.result
  const activeCota = data.bet.prize_result.status === 'hidden' ? 'active-square-gray' : 'active-square'
  const inactiveCota = data.bet.prize_result.status === 'hidden' ? 'inative-square-red' : 'inative-square'
  return (
    <>
      <ul className="list historic-list">
        <li>
          <span className="font-500">
            <span className={`${activeCota}`} />
            Cotas ativas
          </span>
          <span className="font-500">{data.bet.qnty_active}</span>
        </li>
        <li>
          <span className="font-500">
            <span className={`${inactiveCota}`} />
            Cotas inativas
          </span>
          <span className="font-500">{data.bet.qnty_inactive}</span>
        </li>
      </ul>
      <h1 className="historic-title">Histórico do bolão</h1>
      <div id="historic-timeline-container">
        <Timeline align="left" className="historic-timeline">
          {historicData.map((item) => (
            <TimelineItem key={item.id}>
              <TimelineOppositeContent className="historic-timeline-date">
                <Typography color="textSecondary">
                  {item.updated_at}
                </Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Typography>{item.description}</Typography>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </div>
    </>
  )
}
