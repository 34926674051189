import React, { Fragment } from 'react';
import moment from 'moment';
import slugify from 'slugify';

import Slider from '@material-ui/core/Slider';
import FilterListIcon from '@material-ui/icons/FilterList';
import CloseIcon from '@material-ui/icons/Close';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import jackpotsSevices from '../../services/jackpots';
import concourseServices from '../../services/concourses';
// import convertCurrencyWords from '../../helpers/convertCurrencyWords';
import currencyFormat from '../../helpers/currencyFormat';
import Button from '../../components/Button';

import './style.scss';

class Filter extends React.Component {
    _range = [0, 1000];

    constructor(props) {
        super(props);

        this.state = { 
            open:        false, 
            loading:     false,
            
            currentType: null,
            date:        "",
            
            range:      this._range, 
            dayName:    [ 'Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado', 'Hoje' ],
            days:       [ ],
            types:      [ ],
            filter:     { }
        }
        this.row = React.createRef;

        this.doScrolling  = this.doScrolling.bind(this);
        this.handleRange  = this.handleRange.bind(this);
        this.handleFilter = this.handleFilter.bind(this);
        this.showDays     = this.showDays.bind(this);
        this.handleDay    = this.handleDay.bind(this);
        this.doFilter     = this.doFilter.bind(this);
        this.clearParams  = this.clearParams.bind(this);
    }

    async componentDidMount() {
        await this.showDays();
        this.setState({ ...this.state, loading: true });
        const types = await concourseServices.getEstimatedPrizes();
        if(types && types.length)  this.setState({ ...this.state, types: types });
        this.setState({ ...this.state, loading: false });
        
    }
    
    async doFilter(type = null) {
        
        await this.setState({ ...this.state, currentType: type });

        const { date, range } = this.state;
        const _filter = {
            date:  date                  ?  date : null,
            range: range !== this._range ? range : null
        }
        await this.setState({ ...this.state, filter: _filter});

        this.props.action(date, range, type);
        this.handleFilter(false);
    }

    async clearParams(key) {
        const  filter = this.state.filter;
        delete filter[key];

        let date  = filter.date  ? filter.date  : null ;
        let range = filter.range ? filter.range : null ;
              
        this.props.action(date, range, this.state.currentType);

        range = range ? range : this._range ;
        await this.setState({ ...this.state, date, range, filter });
    }

    handleRange(event, newValue) {
        this.setState({ ...this.state, range: newValue });
    }

    handleDay(event) {
        this.setState({ ...this.state, date: event.target.value });
    }

    handleFilter(states) {
        this.setState({ ...this.state, open: states })
    }

    doScrolling(side) {
        var scrollAmount = 0;

        const slideTimer = setInterval(() => {
            if(side === "left") {
                this.row.scrollLeft -= 5;
                scrollAmount        -= 5;
            }
            if(side === "right") {
                this.row.scrollLeft += 5;
                scrollAmount        += 5;
            }
            (scrollAmount >= 100 || scrollAmount <= -100) && window.clearInterval(slideTimer);

        }, 10);
    }

    showDays() {
        const _today = moment().format("DD-MM-YYYY");
        const _days  = [ ];

        for(let i = 1; i < 7; i++) {
            const _date = moment(_today, "DD-MM-YYYY").add(i, "days")
            _days.push({ date: _date.format("DD-MM-YYYY"), dayOfWeek: moment(_date, "DD-MM-YYYY").day() });
        }

        this.setState({ ...this.state, days: _days });
    }

    render() {
        const { open, range, days, dayName, date, 
                currentType, types, loading, filter } = this.state;
        return (
            <Fragment>

                {/* <section id="types">
                    <div className="container">
                        <ArrowBackIosIcon className="scrollLeft" onClick={ event => this.doScrolling("left") } />
                        <div className="row" ref={ element => this.row = element }>
                            <span className={ `item all ${ !currentType ? 'active' : '' }` } 
                                  onClick={ event => this.doFilter(null) }>Todos os bolões</span> */}
                            {
                                // types && types.length
                                //     ? types.map((type, index) => {
                                //             return (
                                //                 <span 
                                //                     className={ `item ${ slugify(type.name, { lower: true }) } ${ type.id === currentType ? 'active' : '' }` } 
                                //                     key={ index } 
                                //                     onClick={ () => this.doFilter(type.id) }>
                                //                         <small>{ type.name }</small>
                                //                         {/* <strong dangerouslySetInnerHTML={{ __html: convertCurrencyWords(type.prize) }}></strong> */}
                                //                         <strong>
                                //                             { 
                                //                                 parseInt(type.prize) > 0
                                //                                     ? currencyFormat(type.prize) 
                                //                                     : 'A definir'
                                //                             }
                                //                         </strong>
                                //                 </span>
                                //             )
                                //         })
                                //     : null
                            }
                            {/* { loading && <span className="loading"><CircularProgress /></span> } */}
                        {/* </div>
                        <ArrowForwardIosIcon className="scrollRight" onClick={ event => this.doScrolling("right") } />
                    </div> */}
                {/* </section> */}

                <section id="filter">
                    <div className="container">
                        <div className="row">
                            <span className="filter-params">
                                <button className="filter-button" onClick={ event => this.handleFilter(true) }><FilterListIcon /> Filtrar</button>
                                {
                                    filter && Object.keys(filter).map((key) => {

                                        if(!filter[key])
                                            return false;

                                        var label;
                                        switch(key) {
                                            case "date":
                                                label = dayName[ moment(date, "DD-MM-YYYY").day() ];
                                                break
                                            case "range":
                                                label = `De ${ currencyFormat(range[0]) } a ${ currencyFormat(range[1]) }`;
                                                break;
                                            default:
                                                label = "";
                                        }
                                        return (<span key={ key } className="params">{ label } <HighlightOffIcon onClick={ event => this.clearParams(key) }/></span>)
                                    })
                                }
                                <section id="filter-box" className={ `animated ${ open ? 'active fadeIn' : 'fadeOut' }` }>
                                    
                                    <CloseIcon className="close-filter" onClick={ event => this.handleFilter(false) } />

                                    <div className="line">
                                        <label>Dia do sorteio</label>
                                        <div className="days">
                                            <label 
                                                className={ `day ${ date && date ===  moment().format("DD-MM-YYYY") ? 'active' : '' }` }
                                                onChange={ this.handleDay }>
                                                    { dayName[7] }
                                                <input type="radio" name="day" value={ moment().format("DD-MM-YYYY") } />
                                            </label>
                                            { 
                                                days && days.map((item, index) => (
                                                    <label 
                                                        key={ index } 
                                                        className={ `day ${ (date && date === item.date) ? 'active' : '' }` } 
                                                        onChange={ this.handleDay }>
                                                            { dayName[item.dayOfWeek] }
                                                        <input type="radio" name="day" value={ item.date } />
                                                    </label>
                                                )) 
                                            }
                                        </div>
                                    </div>

                                    <div className="line">
                                        <label>Valor da cota</label>
                                        <Slider
                                            className="slider-price"
                                            onChange={ this.handleRange }
                                            valueLabelDisplay="auto"
                                            aria-labelledby="non-linear-slider"
                                            value={ range } step={ 10 }
                                            min={ 0 } max={ 1000 }
                                            valueLabelFormat={ value => currencyFormat(value) }
                                        />
                                    </div>

                                    <div className="line">
                                        <Button type="button" color="green"  label="Aplicar filtro" action={ () => this.doFilter(currentType) } />
                                    </div>

                                </section>

                            </span>
                            <span className="num-rows"><strong>{ this.props.totalRows }</strong> bolões encontrados.</span>
                        </div>
                    </div>        
                </section>
            
            </Fragment>
        )
    }
}

export default Filter;