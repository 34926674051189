/* eslint-disable no-return-assign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/prefer-default-export */
import jackpotServices from '../../services/jackpots'

export function handleChange(event) {
  this.setState({
    ...this.state,
    terms: event.target.value,
    clear: !!event.target.value.length,
  })
}

export function clearFunction({ isClear, setTerms }) {
  isClear(false)
  setTerms('')

  this.lines.forEach((item) => {
    if (!item) return

    item.classList.remove('hidden')
    item.style.display = 'flex'
  })
}

export function search(event) {
  event.preventDefault()

  this.lines.forEach((item) => {
    if (!item) return

    item.classList.remove('hidden')
    item.style.display = 'flex'
  })

  const { terms } = this.state
  this.lines.forEach((item) => {
    if (!item) return

    const gameId = item.querySelector('.id')
    const index = gameId.innerText.toLowerCase().indexOf(terms.toLowerCase())

    if (index === -1) {
      item.classList.add('hidden')
      setTimeout(() => (item.style.display = 'none'), 500)
    }
  })
}

export function seeMore() {
  this.setState({ ...this.state, gamesOpen: true })
}

export async function loadMore() {
  this.setState({ ...this.state, loading: true })
  const { pagination } = this.state

  const { data } = await jackpotServices.getGame({
    jackpot: this.props.jackpot.id,
    page: pagination.next,
  })
  const _array = this.state.games

  if (data && data.result.length) { await data.result.map((item) => _array.push(item)) }

  await this.setState({
    ...this.state,
    loading: false,
    finished: !(data && data.pagination.next),
    pagination: data && data.pagination,
    orders: _array,
  })
}

export function groupBy(list, keyGetter) {
  const map = new Map()
  list.forEach((item) => {
    const key = keyGetter(item)
    const collection = map.get(key)
    if (!collection) {
      map.set(key, [item])
    } else {
      collection.push(item)
    }
  })
  return map
}

export function hasBetFunction(data) {
  if (data.bet) {
    return true
  }
  return false
}
export function hasPrizeResult(data) {
  if (data.bet) {
    return data.bet.prize_result.status !== 'hidden'
  }
  return false
}

export function hasBetActives(data) {
  if (data.bet) {
    return Object.keys(data.bet).length !== 0 && data.bet.qnty_active !== 0
  }

  if (data.jackpot) {
    return true
  }
  return false
}
