import React from 'react';

import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
import CloseIcon from '@material-ui/icons/Close';

import privacy from '../../data/privacy-terms';
import { UseTermsHTML } from '../../data/use-terms';

import './style.scss';

class JackpotModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      types: [{ id: 'privacy', content: privacy.data }, { id: 'use', content: UseTermsHTML() }],
    };
  }

  render() {
    const { handleModal, open, type } = this.props;
    const _type = type ? this.state.types.filter((item) => item.id === type) : [];

    return (
      <Modal
        open={open}
        onClose={(event) => handleModal(type)}
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
        closeAfterTransition
        className="terms-modal"
        aria-labelledby="modal-title"
        aria-describedby="modal-body"
      >
        <Fade in={open}>
          <div className="modal-box">

            <CloseIcon className="close-modal" onClick={handleModal} />

            <div className="modal-body">
              { _type.length ? (<span dangerouslySetInnerHTML={{ __html: _type[0].content }} />) : null }
            </div>

          </div>
        </Fade>
      </Modal>
    );
  }
}

export default JackpotModal;
