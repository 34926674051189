/* eslint-disable linebreak-style */
/* eslint-disable max-len */
/* eslint-disable consistent-return */
import { types } from './types';
import accountServices from '../../services/account';
import walletServices from '../../services/wallet';

export function logout() {
  return async (dispatch) => {
    try {
      dispatch({ type: types.SET_USER_DATA, userData: { } });
      dispatch({ type: types.ADD_USER_ADDRESS, userAddress: { } });
      dispatch({ type: types.SET_USER_BANK, userBank: { } });

      const { token } = JSON.parse(localStorage.getItem('unindo_sonhos_data'));
      localStorage.removeItem('unindo_sonhos_data');
      localStorage.removeItem('cart_items');
      window.location.href = `${process.env.REACT_APP_ON_BOARDING_URL}/redirect?action=logout&token=${encodeURIComponent(token)}`;

      return true;
    } catch (error) {
      console.log(error);
    }
  };
}
export function reset() {
  return async (dispatch) => {
    dispatch({ type: types.RESET });
    return true;
  };
}
export function setBalance() {
  return async (dispatch) => {
    try {
      const { data } = await walletServices.getBalance();
      dispatch({ type: types.SET_BALANCE, balance: data.founds });
      return data;
    } catch (error) {
      console.log(error);
    }
  };
}
export function updateUserData(userData) {
  return async (dispatch) => {
    try {
      dispatch({ type: types.SET_USER_DATA, userData });
      const { data } = await accountServices.updateUserData(userData);
      return data;
    } catch (error) {
      console.log(error);
    }
  };
}
export function getBankAccounts() {
  return async (dispatch) => {
    try {
      const { data } = await accountServices.getBankAccounts();
      dispatch({ type: types.SET_USER_BANK, userBank: data.result });
      return data.result;
    } catch (error) {
      console.log(error);
    }
  };
}
export function addUserBank(userBank) {
  return async (dispatch) => {
    try {
      const { data } = await accountServices.addBankAccount(userBank);
      dispatch({ type: types.ADD_USER_BANK, payload: userBank });
      return data;
    } catch (error) {
      console.log(error);
    }
  };
}
export function removeBankAccount(userBank) {
  return async (dispatch) => {
    try {
      await accountServices.removeBankAccount(userBank);
      dispatch({ type: types.REMOVE_USER_BANK, payload: userBank });
      return true;
    } catch (error) {
      console.log(error);
    }
  };
}
export function setDefaultAccount(account) {
  return async (dispatch) => {
    try {
      await accountServices.updateBankAccount(account);
      const { data } = await accountServices.getBankAccounts();
      dispatch({ type: types.SET_USER_BANK, userBank: data.result });
      return data;
    } catch (error) {
      console.log(error);
    }
  };
}
export function updateUserAddress(userAddress) {
  return async (dispatch) => {
    try {
      await accountServices.updateUserAddress(userAddress);
      dispatch({ type: types.ADD_USER_ADDRESS, userAddress });
      return true;
    } catch (error) {
      console.log(error);
    }
  };
}
export function me() {
  return async (dispatch) => {
    try {
      const { data } = await accountServices.getMe();
      dispatch({ type: types.SET_USER_DATA, userData: data.customer?.personal_information });
      dispatch({ type: types.ADD_USER_ADDRESS, userAddress: data.customer?.address });
      dispatch({ type: types.NEED_ACCEPT_TERM, needAcceptTerm: data.customer?.need_accept_term });
      const response = await walletServices.getBalance();
      if (!response) {
        dispatch({ type: types.SET_BALANCE, balance: -1 });
      } else {
        dispatch({ type: types.SET_BALANCE, balance: response.data.founds });
      }

      return data;
    } catch (error) {
      console.log(error);
    }
  };
}
