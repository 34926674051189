import React from 'react';

import LetsPlay from '../../sections/LetsPlay';
import paulinha from '../../assets/images/paulinha.png';
import './style.scss';

class About extends React.Component {
  render() {
    return (
      <main id="about">

        <section id="content-about">
          <div className="container">

            <div className="row">
              <h1>Quem somos</h1>
            </div>

            <div className="row">
              <figure>
                <img src={paulinha} alt="Foto da Paulinha, responsável pelo Unindo Sonhos." />
              </figure>

              <div className="text">
                <p>
                  A Unindo Sonhos foi criada a partir de uma "brincadeira" da CEO da Unindo Sonhos,
                  Paulinha Leite, com seus seguidores do Instagram. Paulinha, que participou do
                  Big Brother Brasil, em 2011, teve destaque no reality após ganhar várias provas
                  que envolviam sorte. Através de provas com outros participantes, ela ganhou um
                  apartamento, moto, carro e valores em dinheiro. Sua sorte já era de longa data e
                  após a sua saída do reality, foram divulgadas as diversas vezes que Paulinha ganhou
                  na Loteria Federal, raspadinhas e sorteios. Sua sorte chamou atenção de seus seguidores,
                  que começaram a pedir números para realizarem apostas. Estes números sempre foram divulgados
                  em suas redes sociais de forma espontânea. Foi  aí que começaram a chegar os depoimentos das
                  pessoas que ganharam premiações com os números passados por ela.
                </p>
                <p>
                  Paulinha então, atendendo a pedidos de seus seguidores resolveu  fazer seu primeiro bolão e
                  de primeira, já teve a premiação de R$41.919,15.
                </p>
                <p>
                  Assim nasceu a Unindo Sonhos...
                </p>
                <p>
                  A notícia do seu primeiro prêmio tomou grandes proporções e hoje a
                  Unindo Sonhos é uma empresa legalmente estabelecida na Região Norte.
                  Possui uma equipe totalmente qualificada, atenciosa e preparada para
                  grande demanda dos bolões da Unindo Sonhos, que em menos de 40 dias
                  de criação já foi premiada inúmeras vezes totalizando o valor de mais de
                  R$ 300.000,00 (Trezentos mil reais).
                </p>
                <p>
                  A Unindo sonhos tem o propósito de ajudar pessoas a realizar metas,
                  sonhos e objetivos e através dos nossos jogos, mostramos que é muito
                  possível chegar à realidade.
                </p>
                <p>
                  Disponibilizamos a melhor plataforma de jogos on line para que você
                  sinta-se seguro e tenha toda comodidade de apostar sem sair de casa e de
                  qualquer lugar do mundo!
                </p>
                <p>
                  Seja bem-vindo a Unindo Sonhos e Boa Sorte !
                </p>

                <h2>Missão</h2>
                <p>Proporcionar aos nossos clientes esperança,  oportunidade de mudança de vida e diversão consciente,  através dos nossos bolões.</p>

                <h2>Visão</h2>
                <p>Ser uma empresa referência no ramo,  reconhecida e respeitada pela qualidade dos nossos serviços e crescente satisfação de nossos clientes</p>

                <h2>Valores</h2>
                <p>Prezamos pela transparência e mantemos o compromisso de prestar um serviço de qualidade, de forma íntegra e responsável.</p>
              </div>

            </div>

          </div>
        </section>

        <LetsPlay />

      </main>
    );
  }
}

export default About;
