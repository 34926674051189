/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CircularProgress } from '@mui/material';
import Button from '../../../components/Button';
import { history } from '../../../helpers/history';
import terms from '../../../services/terms';
import formatDate from '../../../helpers/formatDate';
import { handleCheckoutStep } from '../index';

export default function TermsStep({ setCheckoutData, setLoading, setStep }) {
  const [term, setTerm] = useState('');
  const [loadingTerm, setLoadingTerm] = useState(true);
  const reduxDispatch = useDispatch();

  useEffect(() => {
    getTerms();
  }, []);

  async function getTerms() {
    setLoadingTerm(true);
    await terms.getTerms().then((data) => {
      setTerm(data);
    });
    setLoadingTerm(false);
  }

  async function acceptTerms() {
    setLoadingTerm(true);
    await terms.acceptTerms().then(async (data) => {
      if (data.status === 200) {
        if (data.data.data.accepted) {
          await reduxDispatch({ type: 'NEED_ACCEPT_TERM', value: false });
	        await handleCheckoutStep(setCheckoutData, setStep, setLoading);
        }
      }
    });
  }
  return (
    <>
      <section id="checkout-step-terms">
        {!loadingTerm ? (
          <>
            <h1 className="title">
              {`${term?.name} - ${formatDate(term?.updated_at)}`}
            </h1>
            <div className="container-text">
              <div
                className="content"
                dangerouslySetInnerHTML={{
                  __html: term?.content_text,
                }}
              />
            </div>
            <div className="buttons">
              <Button action={acceptTerms} width="100%" color="green" label="Aceito os termos" loading={loadingTerm} />
              <button type="button" className="text-button" onClick={() => history.push('/')}>Eu não aceito os termos</button>
            </div>
          </>
        ) : <span className="loading"><CircularProgress /></span>}
      </section>
    </>
  );
}
