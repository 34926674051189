/* eslint-disable no-unused-expressions */
import cartService from '../../services/cart';
import { types } from './types';

export function addToCart(response) {
  return async (dispatch) => {
    try {
      dispatch({ type: types.CLEAR_CART_CREDITS });
      dispatch({ type: types.ADD_TO_CART, ...response?.data });
      dispatch({ type: types.UPDATE_EXPIRY, expiry_seconds_at: response?.data?.expiry_seconds_at });

	    response?.data?.cartKey && localStorage.setItem('cartKey', response?.data?.cartKey);
    } catch (error) {
      console.log(error);
    }
  };
}
export function addToCartCredits(response, credits) {
  return async (dispatch) => {
    try {
      dispatch({ type: types.CLEAR_CART });
      dispatch({ type: types.ADD_CREDITS, credits });
	    response?.data?.cartKey && localStorage.setItem('cartKey', response.data.cartKey);
    } catch (error) {
      console.log(error);
    }
  };
}

export function removeToCart(response) {
  return async (dispatch) => {
    try {
      dispatch({ type: types.REMOVE_TO_CART, ...response?.data });
    } catch (error) {
      console.log(error);
    }
  };
}

export function expireCart() {
  return async (dispatch) => {
    try {
      dispatch({ type: types.EXPIRE_CART_FEEDBACK, value: true });
    } catch (error) {
      console.log(error);
    }
  };
}

export function updateCart(cart = null) {
  return async (dispatch) => {
    try {
      if (localStorage.getItem('cartKey')) {
        if (!cart?.content) {
          await cartService.getCart(localStorage.getItem('cartKey')).then((response) => {
            if (response.code === 904 || response.code === 0) { // CARRINHO EXPIRADO OU INEXISTENTE
              localStorage.removeItem('cartKey');
              updateCart([]);
            }
            if (response.data?.content[0].options?.wallet_topup) return null;
            dispatch({ type: types.UPDATE_CART, ...response.data });
            dispatch({ type: types.UPDATE_EXPIRY, expiry_seconds_at: response.data?.expiry_seconds_at });
          });
          return;
        }
      }

      dispatch({ type: types.UPDATE_CART, ...cart });

      if (cart?.content?.length === 0) {
        dispatch({ type: types.UPDATE_EXPIRY, expiry_seconds_at: 0 });
      }
    } catch (error) {
      console.log(error);
    }
  };
}
export function clearAllCarts() {
  return async (dispatch) => {
    try {
      dispatch({ type: types.CLEAR_CART });
      dispatch({ type: types.CLEAR_CART_CREDITS });
      dispatch({ type: types.UPDATE_EXPIRY, expiry_seconds_at: 0 });
      localStorage.removeItem('cartKey');
      return true;
    } catch (error) {
      console.log(error);
    }
  };
}

export function destroyCart() {
  return async (dispatch) => {
    try {
      dispatch({ type: types.CLEAR_CART });
      dispatch({ type: types.CLEAR_CART_CREDITS });
      dispatch({ type: types.UPDATE_EXPIRY, expiry_seconds_at: 0 });
      localStorage.removeItem('cartKey');
      return true;
    } catch (error) {
      console.log(error);
    }
  };
}
