import React from 'react';
import InputMask from 'react-input-mask';
import SimpleReactValidator from 'simple-react-validator';

import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';

import Button from '../../components/Button';
import contactServices from '../../services/contact';

import './style.scss';

class Home extends React.Component {
  _feedback = { show: false, severity: 'info', message: '' };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,

      form: {
        name: '', phone: '', email: '', message: '',
      },

      feedback: this._feedback,
    };

    const _params = {
      messages: {
        required: 'Esse campo é obrigatório', email: 'Esse e-mail é inválido', min: 'É necessário ter no mínimo 6 caracteres',
      },
    };

    this.submitForm = this.submitForm.bind(this);
    this.validator = new SimpleReactValidator(_params);
  }

  handleChange(event, field) {
    this.setState({ ...this.state, form: { ...this.state.form, [field]: event.target.value } });
  }

  async submitForm(event) {
    event.preventDefault();

    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return;
    }

    this.setState({ ...this.state, loading: true });
    const response = await contactServices.sendMail(this.state.form);
    if (response) {
      this.setState({
        ...this.state,
        form: {
          name: '', phone: '', email: '', message: '',
        },
      });
    }

    const _feedback = (response) ? { show: true, severity: 'success', message: 'Enviado com sucesso!' } : { show: true, severity: 'warning', message: 'Ops. O serviço de e-mail não está online.' };

    this.setState({ ...this.state, feedback: _feedback, loading: false });
    setTimeout(() => this.setState({ ...this.state, feedback: this._feedback }), 3000);
  }

  render() {
    const {
      name, phone, email, message,
    } = this.state.form;
    const { loading, feedback } = this.state;

    return (
      <main id="contact">

        <form id="formulary" onSubmit={this.submitForm} noValidate>

          <div className="form-group">
            <h1>Entre em contato</h1>
            <Collapse className="feedback" in={feedback.show}>
              <Alert severity={feedback.severity}>{feedback.message}</Alert>
            </Collapse>
          </div>

          <div className="form-group">
            <label>Nome*</label>
            <input type="text" className="form-control" value={name} onChange={(event) => this.handleChange(event, 'name')} />
            {this.validator.message('Nome', name, 'required')}
          </div>

          <div className="form-group">
            <label>Telefone*</label>
            <InputMask
              name="dob"
              mask="(99) 99999-9999"
              onChange={(event) => this.handleChange(event, 'phone')}
              value={phone}
            >
              {(inputProps) => <input type="text" className="form-control" {...inputProps} />}
            </InputMask>
            {this.validator.message('Telefone', phone, 'required')}
          </div>

          <div className="form-group">
            <label>E-mail*</label>
            <input type="text" className="form-control" value={email} onChange={(event) => this.handleChange(event, 'email')} />
            {this.validator.message('E-mail', email, 'required')}
          </div>

          <div className="form-group">
            <label>Mensagem*</label>
            <textarea
              className="form-control"
              onChange={(event) => this.handleChange(event, 'message')}
              value={message}
            />
            {this.validator.message('Mensagem', message, 'required')}
          </div>

          <div className="form-group">
            <Button type="submit" disabled={loading} loading={loading} color="green" label="Enviar" />
          </div>

        </form>

      </main>
    );
  }
}

export default Home;
