/* eslint-disable no-unused-expressions,no-nested-ternary,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import { Star, StarBorder } from '@material-ui/icons';
import currencyFormat from '../../../../../helpers/currencyFormat';
import Button from '../../../../../components/Button';
import account from '../../../../../services/account';
import Checkbox from '../../../../../components/Checkbox';

import VisaBrand from '../../../../../assets/images/bandeira-visa.svg';
import MasterCardBrand from '../../../../../assets/images/bandeira-mastercard.svg';
import EloCardBrand from '../../../../../assets/images/bandeira-elo.svg';
import AmexCardBrand from '../../../../../assets/images/bandeira-amex.svg';

function getBandeira(brand) {
  let bandeira;
  switch (brand.toLowerCase()) {
    case 'visa':
      bandeira = <img alt={brand} src={VisaBrand} width={43} height={43} />;
      break;
    case 'mastercard':
      bandeira = <img alt={brand} src={MasterCardBrand} width={43} height={43} />;
      break;
    case 'amex':
      bandeira = <img alt={brand} src={AmexCardBrand} width={43} height={43} />;
      break;
    case 'elo':
      bandeira = <img alt={brand} src={EloCardBrand} width={43} height={43} />;
      break;
    default:
      bandeira = null;
  }

  return bandeira;
}

export function getPaymentInfo(actions) {
  if (actions.method === 'pix') {
    return (
      <p>Realize o pagamento o quanto antes para garantir sua compra.</p>
    );
  }

  if (actions.method === 'wallet') {
    return (
      <>
        <p>
          <span>Saldo em carteira: </span>
          <strong>{currencyFormat(actions.checkoutData.founds)}</strong>
        </p>
        <p>
          <span>Valor da compra: </span>
          <strong>{currencyFormat(actions.checkoutData.cart.total_price)}</strong>
        </p>
        <hr />
        <p>
          <span>Saldo final da carteira: </span>
          <strong>
            {currencyFormat(
              actions.checkoutData.founds - actions.checkoutData.cart.total_price,
            )}
          </strong>
        </p>
      </>
    );
  }

  if (actions.method === 'boleto') {
    return (
      <p>Pague com boleto bancário.</p>
    );
  }

  if (actions.method === 'credit_card') {
    const { showAllCards, checkoutData: { cards } } = actions;
    const defaultCard = cards.find((card) => card.is_default);
    const defaultAndSessionEqual = showAllCards && defaultCard.id === actions.sessionCard;
    const OneOrZeroCards = cards.length <= 1;

    const userCards = cards.map((card) => {
      const isDefaultCard = card.is_default;
      const isCurrentSessionCard = card.id === actions.sessionCard;
      const hideNotCardSession = !showAllCards && !isCurrentSessionCard;
      const isActive = card.id === actions.sessionCard;
      if (hideNotCardSession) return null;

      const cardLinkDescription = () => {
        if (showAllCards) {
          if (isDefaultCard) {
            return <Star />;
          }
        } else {
          if (OneOrZeroCards) {
            return <Star />;
          }
          return 'Alterar cartão';
        }

        return '';
      };

      return (
        <>
          {actions.loadingCards && (
            <div className="loading-overlay">
              <CircularProgress style={{ width: 20, height: 20 }} />
            </div>
          )}
          <div
            className={`checkout-card-item ${isActive && showAllCards ? 'active' : ''}`}
            onClick={() => actions.setSessionCard(card.id)}
          >
            <div className="card-first">
              {showAllCards && <span className="card-radio" />}
              <div className="card-brand-image">{getBandeira(card.brand)}</div>
              <div className="card-info">
                <div className="card-first">
                  <span className="card-brand">
                    {card.brand}
                  </span>
                  <span className="card-detail">
                    12/24
                  </span>
                </div>
                <span className="card-detail">
                  {`Final ${card.last_four}`}
                </span>
              </div>
            </div>
            <span className={`card-current-state ${!showAllCards ? 'withLink' : ''}`} onClick={() => !showAllCards && !OneOrZeroCards && actions.setShowAllCards(true)}>
              {cardLinkDescription()}
            </span>
          </div>
        </>
      );
    });

    return (
      <div className="cards-checkout">
        <section id="card-selection">
          {userCards}
          {showAllCards && (
            <div>
	            <div className="card-actions">
		            <Button
			            color="green"
			            disabled={actions.loadingCards}
			            type="button"
			            id="card-submit"
			            action={() => setSessionDefaultCard(actions)}
			            label="Alterar pagamento"
		            />

		            <Checkbox
			            label="Tornar principal"
			            name="setToDefault"
			            disabled={defaultAndSessionEqual}
			            id="setToDefault"
			            onClick={() => actions.setCardToDefault((state) => !state)}
			            checked={actions.cardToDefault}
		            />
	            </div>
            </div>
          )}
	        <a style={{
						marginTop: showAllCards ? "16px" : "4px",
		        color: "green",
		        fontSize: "14px"
					}}
	           href={`${process.env.REACT_APP_ON_BOARDING_URL}/dashboard/cartoes`}>
		        Cadastrar novo cartão
	        </a>
        </section>
      </div>
    );
  }

  return (
    <p>Selecione uma forma de pagamento válida</p>
  );
}

export function showPayForm(actions) {
  return (
    <div className="payment-box">
      <div className="content">
        {getPaymentInfo(actions)}
      </div>
    </div>
  );
}

export async function setSessionDefaultCard(actions) {
  actions.setCheckoutData((state) => ({
    ...state,
    sessionDefaultCard: actions.sessionCard,
  }));

  if (actions.cardToDefault) {
    await overrideDefaultCard(actions);
    actions.setCardToDefault(false);
  }

  actions.setSessionCard(actions.sessionCard);
  actions.setShowAllCards(false);
}

export async function overrideDefaultCard(actions) {
  actions.setLoadingCards(true);
  await account.updateCard(actions.sessionCard).then(() => {
    actions.updateCardList();
  });
  actions.setLoadingCards(false);
}
