import React from 'react';

import registerImg from '../../assets/images/icons/cadastro.svg';
import selectImg from '../../assets/images/icons/escolha.svg';
import paymentImg from '../../assets/images/icons/pagamento.svg';
import doneImg from '../../assets/images/icons/torcer.svg';

import './style.scss';

class SectionHowWork extends React.Component {
  render() {
    return (
      <section id="section-work">
        <div className="container">
          <div className="row">
            <h1>Como funciona o Unindo Sonhos.</h1>
          </div>
          <div className="row">

            <article>
              <figure><img src={registerImg} alt="Ícone ilustrando a etapa de cadastro" /></figure>
              <h1>Faça seu cadastro ou efetue o login.</h1>
              <p>O usuário deve ter mais de 18 anos para participar.</p>
            </article>

            <article>
              <figure><img src={selectImg} alt="Ícone ilustrando a etapa de escolha do bolão" /></figure>
              <h1>Escolha um ou mais bolões.</h1>
              <p>Para apostar você deve indicar a quantidade de cotas que deseja e realizar a compra.</p>
            </article>

            <article>
              <figure><img src={paymentImg} alt="Ícone ilustrando a etapa de pagamento na plataforma" /></figure>
              <h1>Escolha a forma de pagamento.</h1>
              <p>Se você quiser agilizar o processo, e possível comprar créditos, ou então pagar no cartão de crédito.</p>
            </article>

            <article>
              <figure><img src={doneImg} alt="Ícone ilustrando a etapa de conclusão do processo" /></figure>
              <h1>Agora é só torcer.</h1>
              <p>Mandamos os resultados de todas as apostas por e-mail. Os prêmios ficarão na sua conta virtual, você pode sacar quando quiser.</p>
            </article>

          </div>
        </div>
      </section>
    );
  }
}

export default SectionHowWork;
