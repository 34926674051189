import api   from './api';


const phoneValidation = {
    async sendPinCode(phone){
        try {
            let _url = `/customer/send-sms`;
            
            const { data } = await api.post(_url,{receiverNumber:'+55'+phone});
            return data;
        }
        catch(error) {
            console.log(error);
            return false;
        }
    },
    async validatePinCode(pin,cpf_user){
        try {
            let _url = `/customer/validate-pin`;
            
            const { data } = await api.post(_url,{pin,cpf_user});
            return data;
        }
        catch(error) {
            console.log(error);
            return false;
        }
    },
    async validPinCache(cpf_user){
        try {
            let _url = `/customer/get-cache-pin`;
            
            const { data } = await api.post(_url,{cpf_user});
            return data;
        }
        catch(error) {
            console.log(error);
            return false;
        }
    },
    async setPhone(phone){
        try {
            let _url = `/customer/edit-account`;
            
            const { data } = await api.post(_url,{phone});
            return data;
        }
        catch(error) {
            console.log(error);
            return false;
        }
    }
}

export default phoneValidation;