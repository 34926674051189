/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import Axios from 'axios';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

/* eslint-disable no-return-assign */
/* eslint-disable consistent-return */
import { useMemo } from 'react';
import * as appActions from '../store/app/actions';
import getLoggedUserInfo from '../helpers/getLoggedUserInfo';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;
const api = Axios.create({ baseURL: BASE_URL });

export const WithAxios = ({ children }) => {
  const requestInterceptor = (request) => {
    const userInfo = getLoggedUserInfo();
    if (userInfo?.token) {
      request.headers.Authorization = `Bearer ${userInfo?.token}`;
    }

    return request;
  };

  const responseErrorInterceptor = (error) => {
    if (error.response.status === 429 && error.response.data.message === 'Too Many Attempts.') {
      return;
    }

    const urls = ['/comprar-creditos', '/', '/sobre', '/boloes', '/quem-somos', '/como-funciona'];
    if (error.response.status === 401 || error.response.status === 0) { // STATUS 0 = RESPOSTA DA API BAD GATEWAY 502
	    console.log('entrou fora do if, error interceptor');
      if (urls.indexOf(window.location.pathname) === -1) {
	      console.log('entrou no if');
        // localStorage.removeItem('unindo_sonhos_data');
        return window.location.href = `${process.env.REACT_APP_ON_BOARDING_URL}/login?action=auth&redirect_to=${encodeURIComponent(window.location.href)}`;
      }
    }

    return Promise.reject(error);
  };

  const responseSuccessInterceptor = (response) => response;

  useMemo(() => {
    api.interceptors.request.use(requestInterceptor);

    api.interceptors.response.use(
      (response) => responseSuccessInterceptor(response),
      (error) => responseErrorInterceptor(error),
    );
  }, []);

  return children;
};

export default api;
