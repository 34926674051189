import React from 'react'
import { NavLink } from 'react-router-dom'

import CloseIcon from '@material-ui/icons/Close'
import accountService from '../../services/account'

import './style.scss'

class PhonePopUp extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      closed: false,
    }
    this.closeModal = this.closeModal.bind(this)
  }

  async componentDidMount() {

  }

  closeModal() {
    setTimeout(() => {
      this.setState({ ...this.state, closed: true })
    }, 3000)
  }

  render() {
    const { closed } = this.state
    return (
      !closed
        ? (
          <div id="container-background">
            <div id="container-modal-global" className={`box ${this.state.closed ? 'closed' : ''}`}>
              {this.props.closable ? <CloseIcon className="close" onClick={() => this.setState({ ...this.state, closed: true })} /> : ''}

              <span className="title">{this.props.title ? this.props.title : ''}</span>
              <span className="subtitle">{this.props.subtitle ? this.props.subtitle : ''}</span>
              <button className="btn" onClick={() => { this.setState({ ...this.state, closed: true }) }}>{this.props.buttonText}</button>
            </div>
          </div>
        )
        : ''
    )
  }
}

export default PhonePopUp
