/* eslint-disable linebreak-style */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { v4 as uuidv4 } from 'uuid';
import CryptoJS from 'crypto-js';

import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';

import * as appActions from '../../store/app/actions';
import * as accountActions from '../../store/account/actions';
import AuthService from '../../services/auth';

import { history } from '../../helpers/history';
import Button from '../Button';
import bigLogo from '../../assets/images/logotipo-unindo-sonhos.png';

import './style.scss';

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user_id: '',
      password: '',

      // email:    'vento@plastikastudio.com.br',
      // password: 'asdasd',

      showPass: false,
      feedback: false,
      loading: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleRegister = this.handleRegister.bind(this);
    this.doLogin = this.doLogin.bind(this);
  }

  componentDidUpdate() {
    this.props.appData.openLogin
      ? document.body.classList.add('no-scroll-login')
      : document.body.classList.remove('no-scroll-login');
  }

  handleChange(event) {
    this.setState({ ...this.state, [event.target.id]: event.target.value });
  }

  handleRegister(event) {
    event.preventDefault();

    if (history.location.pathname === '/cadastro') {
      this.props.appActions.handleLogin();
    } else {
      history.push('/cadastro');
      this.props.appActions.handleLogin();
    }
  }

  async doLogin(event) {
    event.preventDefault();

    this.setState({ ...this.state, loading: true });
    const { user_id, password } = this.state;
    const response = await AuthService.authenticate(user_id, password);

    if (response) {
      const sessionId = CryptoJS.MD5(uuidv4());
      localStorage.setItem('unindo_sonhos_data', JSON.stringify({ token: response.access_token, sessionId: sessionId.toString() }));
      await this.props.accountActions.me();
      this.props.appActions.handleLogin();

      if (history.location.pathname === '/checkout') { history.push('/checkout'); } else { history.push('/dashboard'); }
    } else {
      this.setState({ ...this.state, feedback: { show: true, severity: 'warning', message: 'Usuário ou senha inválidos' } });
      setTimeout(() => this.setState({ ...this.state, feedback: { ...this.state.feedback, show: false } }), 5000);
    }
    this.setState({ ...this.state, loading: false });
  }

  render() {
    return (
      null
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.accountReducer.userData,
  appData: state.appReducer,
});
const mapDispatchToProps = (dispatch) => ({
  appActions: bindActionCreators(appActions, dispatch),
  accountActions: bindActionCreators(accountActions, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(Login);
