/* eslint-disable linebreak-style */
import Axios from 'axios';
import api from './api';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;
const publicRequest = Axios.create({ baseURL: BASE_URL });

const jackpots = {
  async getJackpotsType() {
    try {
      const { data } = await publicRequest.get('/jackpots/type-games');
      return data.data;
    } catch (error) {
      console.log(error);
      return false;
    }
  },
  async getJackpots(params = null) {
    try {
      let _url = '/jackpots?target_channel=web_site';

      _url += params && params.date ? `&draw_date=${params.date}` : '';
      _url
				+= params && params.range
				  ? `&min_price=${params.range[0]}&max_price=${params.range[1]}`
				  : '';
      _url += params && params.type ? `&type_games[]=${params.type}` : '';
      _url += params && params.concourse_id ? `&concourse_id=${params.concourse_id}` : '';
      _url += params && params.page ? `&page=${params.page}` : '';
      _url += params && params.status ? `&status=${params.status}` : '';
      _url += params && params.sku ? `&q=${params.sku}` : '';
      _url += params && params.ref ? `&q=${params.ref}` : '';

      const { data } = await publicRequest.get(_url);
      return data;
    } catch (error) {
      console.log(error);
      return false;
    }
  },
  async getJackpot(id) {
    try {
      const _url = `/jackpots/${id}`;

      const { data } = await publicRequest.get(_url);
      return data;
    } catch (error) {
      console.log(error);
      return false;
    }
  },
  async viewJackpot(id) {
    try {
      const _url = `/jackpots/${id}?include=games`;

      const { data } = await publicRequest.get(_url);
      return data.data[0];
    } catch (error) {
      console.log(error);
      return false;
    }
  },
  async getGames(params) {
    try {
      const { data } = await publicRequest.get(
        `/jackpots/${params.jackpot}/games`,
        { params: { page: params.page || 1 } },
      );
      return data;
    } catch (error) {
      return false;
    }
  },
  async getHistoric(betId) {
    try {
      const { data } = await api.get(`/customer/bettings/${betId}/histories`);
      return data.data;
    } catch (error) {
      return false;
    }
  },
};
export default jackpots;
