/* eslint-disable linebreak-style */
/* eslint-disable react/prop-types */
/* eslint-disable consistent-return */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-len */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CurrencyInput from 'react-currency-input';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';

import * as appActions from '../../store/app/actions';
import * as cartActions from '../../store/cart/actions';

import { history } from '../../helpers/history';
import currencyFormat from '../../helpers/currencyFormat';
import Button from '../../components/Button';
import Warning from '../../components/WarningModal';

import './style.scss';
import cartService from '../../services/cart';

class BuyCredits extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      disabled: false,
      loading: false,
      credits: 0.00,

      labels: [1000, 500, 250, 100, 50, 30],
      feedback: { show: false, severity: 'info', message: '' },
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleCredits = this.handleCredits.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event, field) {
    this.setState({ ...this.state, [field]: parseFloat(event.target.value) });
  }

  handleCredits(event) {
    let value = event.target.value.split('R$ ').pop();
    value = value.split('.').join('');
    value = value.replace(/,/g, '.');
    this.setState({ ...this.state, credits: Number(value) });
  }

  async handleSubmit(event) {
    event.preventDefault();

    if (this.disabled) { return false; }

    if (!this.state.credits) {
      this.setState({
        ...this.state,
        feedback: { show: true, severity: 'warning', message: 'Você precisa escolher ou inserir um valor' },
      });
      setTimeout(() => this.setState({ ...this.state, feedback: { show: false, severity: 'info', message: '' } }), 4000);
      return;
    }

    if (this.state.credits < 30) {
      this.setState({
        ...this.state,
        feedback: { show: true, severity: 'warning', message: 'O valor mínimo para a compra de créditos é de R$ 30,00' },
      });
      setTimeout(() => this.setState({ ...this.state, feedback: { show: false, severity: 'info', message: '' } }), 4000);
      return;
    }

    this.setState({ ...this.state, loading: true });

    const _credit = {
      name: 'Crédito',
      type: 'credit_in_wallet',
      amount: this.state.credits,
      qty: 1,
      wallet_topup: true,
    };
    await cartService.addItemToCart(null, null, localStorage.getItem('cartKey'), this.state.credits).then((response) => {
      this.props.cartActions.addToCartCredits(response, _credit);
    });

    this.setState({ ...this.state, loading: false });

    if (Object.entries(this.props.userData).length === 0) {
      window.location.href = `${process.env.REACT_APP_ON_BOARDING_URL}/login?redirect_to=${window.location.origin}/checkout&action=auth`;
    }

    history.push('/checkout');
  }

  render() {
    const {
      labels, loading, disabled, credits,
    } = this.state;
    return (
      <main id="buy-credits">

        <section id="buttons">
          <div className="container">

            <form onSubmit={this.handleSubmit} noValidate>

              <div className="row">
                <h1>Comprar créditos</h1>
                <p>Escolha o valor</p>
              </div>

              <div className="row">
                <RadioGroup className="list" aria-label="credit" name="customized-radios" onChange={(event) => this.handleChange(event, 'credits')}>
                  {
                    labels.length
                    && labels.map((item, index) => (
                      <FormControlLabel
                        key={index}
                        className="credit-button"
                        value={item}
                        label={currencyFormat(item)}
                        disabled={false}
                        control={(
                          <Radio
                            disableRipple
                            icon={<CircleUnchecked />}
                            checkedIcon={<CheckCircleIcon className={this.state.credits === item ? 'checked' : null} />}
                            checked={this.state.credits === item}
                          />
                                      )}
                      />
                    ))
                }
                </RadioGroup>

              </div>

              <div className="row">
                <span className="division">
                  <hr />
                  <span>ou</span>
                  <hr />
                </span>
                <p>Insira um valor específico</p>
              </div>

              <div className="row">

                <div className="form-group">
                  <CurrencyInput
                    prefix="R$ "
                    decimalSeparator=","
                    thousandSeparator="."
                    className="form-control"
                    allowEmpty
                    disabled={disabled}
                    value={credits}
                    onChangeEvent={this.handleCredits}
                  />
                </div>

                <div className="form-group totals">
                  <small>Total:</small>
                  <strong>{ currencyFormat(this.state.credits) }</strong>
                </div>

                <div className="form-group">
                  <Collapse className="feedback" in={this.state.feedback.show} style={{ margin: 'auto' }}>
                    <Alert severity={this.state.feedback.severity}>{ this.state.feedback.message }</Alert>
                  </Collapse>
                </div>

                <div className="form-group">
                  <Button type="submit" color="green" loading={loading} label="Comprar crédito" disabled={disabled} />
                </div>

              </div>

            </form>

          </div>
        </section>

        { disabled ? <Warning /> : null }

      </main>
    );
  }
}
const mapStateToProps = (state) => ({
  appData: state.appReducer,
  userData: state.accountReducer.userData,
});
const mapDispatchToProps = (dispatch) => ({
  appActions: bindActionCreators(appActions, dispatch),
  cartActions: bindActionCreators(cartActions, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(BuyCredits);
