/* eslint-disable react/jsx-no-useless-fragment */
import './style.scss'
import React from 'react'
import CotasUsuario from './cotas-do-bolao'
import JackpotInfos from './infos-bolao'
import JackpotGames from './jackpot-games'
import { hasBetActives } from './helper'

export default function TabContent({
  isResult, tabData, data, loading,
}) {
  if (loading) {
    return null
  }

  function setContentByTab() {
    if (!hasBetActives(data)) {
      return <CotasUsuario data={data} />
    }
    switch (tabData.slug) {
    case 'jackpot-infos':
      return <JackpotInfos data={data} />
    case 'jackpot-games':
      return <JackpotGames isResult={isResult} data={data} />
    case 'jackpot-cotas':
      return <CotasUsuario data={data} />
    default:
      return null
    }
  }

  return setContentByTab()
}
