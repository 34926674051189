/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-expressions */
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NavLink } from 'react-router-dom';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

import Button from '../Button';
import currencyFormat from '../../helpers/currencyFormat';
import { history } from '../../helpers/history';
import * as appActions from '../../store/app/actions';
import * as accountActions from '../../store/account/actions';

import './style.scss';

class UserMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = { balance: 0 };
    this.doLogout = this.doLogout.bind(this);
    this.loadEvents = this.loadEvents.bind(this);
  }

  componentDidUpdate() {
    this.loadEvents();
  }

  componentWillUnmount() {
    this.menu && this.menu.removeEventListener('mouseover', (_event) => { });
    this.menu && this.menu.removeEventListener('mouseleave', (_event) => { });
    this.submenu && this.submenu.removeEventListener('mouseover', (_event) => { });
    this.submenu && this.submenu.removeEventListener('mouseleave', (_event) => { });
  }

  doLogout(mobile = false) {
    this.props.accountActions.logout();
    mobile && this.props.appActions.handleMobileMenu();
  }

  loadEvents() {
    this.menu && this.menu.addEventListener('mouseover', (_event) => {
      this.submenu && this.submenu.classList.remove('fadeOut');
      this.submenu && this.submenu.classList.add('active', 'fadeIn');
    });
    this.menu && this.menu.addEventListener('mouseleave', (_event) => {
      this.submenu && this.submenu.classList.remove('fadeIn');
      this.submenu && this.submenu.classList.add('fadeOut');
      setTimeout(() => this.submenu && this.submenu.classList.remove('active'), 500);
    });
    this.submenu && this.submenu.addEventListener('mouseover', (_event) => {
      this.submenu && this.submenu.classList.remove('fadeOut');
      this.submenu && this.submenu.classList.add('active', 'fadeIn');
    });
    this.submenu && this.submenu.addEventListener('mouseleave', (_event) => {
      this.submenu && this.submenu.classList.remove('fadeIn');
      this.submenu && this.submenu.classList.add('fadeOut');
      setTimeout(() => this.submenu && this.submenu.classList.remove('active'), 500);
    });
  }

  render() {
    const { userData } = this.props;
    let first_name = '';
    if (userData && userData.first_name) { first_name = userData.first_name.length > 6 ? `${userData.first_name.substr(0, 10)}...` : userData.first_name; }

    return (
      <>
        {
          this.props.userData && this.props.userData.first_name ? (
            <>
              <span id="user-menu" ref={(element) => this.menu = element}>
                <span className="menu" onClick={() => window.location.href = `${process.env.REACT_APP_ON_BOARDING_URL}/dashboard`}>
                  {first_name}
                  <KeyboardArrowDownIcon />
                </span>
                <ul className="submenu animated" ref={(element) => this.submenu = element}>
                  <li className="user-wallet">
                    <a style={{ color: '#2A2B41' }} href={`${process.env.REACT_APP_ON_BOARDING_URL}/dashboard/minha-carteira`}>
                      <span className="title">Carteira do cliente</span>
                      <span>Saldo: {this.props.balance === -1 ? 'Dado Indisponível' : currencyFormat(this.props.balance)}</span>
                    </a>
                  </li>
                  <li>
                    <a style={{ color: '#2A2B41' }} href={`${process.env.REACT_APP_ON_BOARDING_URL}/dashboard/minhas-apostas`}>Minhas apostas</a>
                  </li>
                  <li>
                    <a style={{ color: '#2A2B41' }} href={`${process.env.REACT_APP_ON_BOARDING_URL}/dashboard/minhas-compras`}>Minhas compras</a>
                  </li>
                  <li>
                    <a style={{ color: '#2A2B41' }} href={`${process.env.REACT_APP_ON_BOARDING_URL}/dashboard/meu-cadastro`}>Meu cadastro</a>
                  </li>
                  <li onClick={() => this.doLogout()}>Sair</li>
                </ul>
              </span>
              <div id="user-menu-mobile">
                <a style={{ color: '#FFF' }} href={`${process.env.REACT_APP_ON_BOARDING_URL}/dashboard`}>
                  <PersonAddOutlinedIcon />
                  Minha conta
                </a>
                <NavLink to="#" onClick={() => { this.doLogout(true); }}>
                  <ExitToAppOutlinedIcon />
                  Sair
                </NavLink>
                <span style={{ color: '#FFF' }} onClick={() => window.location.href = `${process.env.REACT_APP_ON_BOARDING_URL}/dashboard/minha-carteira`} className="show-balance">
                  <MonetizationOnIcon />
                  Saldo: {this.props.balance === -1 ? 'Dado Indisponível' : currencyFormat(this.props.balance)}
                </span>
              </div>
            </>
          )
            : (
              <>
                <span id="account">
                  <Button type="button" color="green" label="Criar conta" action={() => window.location.href = `${process.env.REACT_APP_ON_BOARDING_URL}/validate?redirect_to=dashboard&action=auth`} />
                  <Button type="button" color="invert" label="Entrar" action={() => window.location.href = `${process.env.REACT_APP_ON_BOARDING_URL}/login?redirect_to=dashboard&action=auth`} />
                </span>
                <div id="account-mobile">
                  <NavLink to="#" onClick={() => window.location.href = `${process.env.REACT_APP_ON_BOARDING_URL}/login?redirect_to=dashboard&action=auth`}>
                    <ExitToAppOutlinedIcon />
                    Entrar
                  </NavLink>
                  <NavLink to="#" onClick={() => window.location.href = `${process.env.REACT_APP_ON_BOARDING_URL}/validate?redirect_to=dashboard&action=auth`}>
                    <PersonAddOutlinedIcon />
                    Criar conta
                  </NavLink>
                </div>
              </>
            )
        }
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  appData: state.appReducer,
  userData: state.accountReducer.userData,
  balance: state.accountReducer.balance,
});
const mapDispatchToProps = (dispatch) => ({
  appActions: bindActionCreators(appActions, dispatch),
  accountActions: bindActionCreators(accountActions, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(UserMenu);
