import winning from '../assets/images/icons/winning.svg'
import waiting from '../assets/images/icons/waiting.svg'
import noWinner from '../assets/images/icons/no-winner.svg'
import cancelled from '../assets/images/icons/cancelled.svg'
import hidden from '../assets/images/icons/close-red.svg'

export const resultIcon = {
  winning,
  waiting,
  'no-winner': noWinner,
  cancelled,
  hidden,
}

export const resultStatus = {
  winning: 'Prêmio do Grupo',
  waiting: 'Aguardando sorteio',
  'no-winner': 'Bolão não premiado',
  cancelled: 'Bolão cancelado',
  hidden: 'Bolão reembolsado',
}
