import api from './api';

const wallet = {
  async getWallet(page = null) {
    try {
      let _url = '/customer/wallet';
      _url += page ? `?page=${page}` : '';
      const data = await api.get(_url);

      if (data.status != 200) {
        return false;
      }
      return data.data;
    } catch (error) {
      console.log(error);
      return false;
    }
  },
  async getBalance() {
    try {
      const data = await api.get('/customer/wallet/balance');
      if (data.status != 200) {
        return false;
      }
      return data.data;
    } catch (error) {
      console.log(error);
      return false;
    }
  },
  async withdrawal(amount) {
    try {
      const { data } = await api.post('/customer/wallet/withdrawal', { amount });
      return data;
    } catch (error) {
      console.log(error);
      return error;
    }
  },
};
export default wallet;
