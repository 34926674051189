import api from './api';

const banners = {
  async getBanners() {
    try {
      const _url = '/campaigns/banners';
      const { data } = await api.get(_url);
      return data;
    } catch (error) {
      console.log(error);
      return false;
    }
  },
};
export default banners;
