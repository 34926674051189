const formatExtensive = (value) => {
    if(value >= 1000000000) {
        return (value % 1 === 0 ? (value/1000000000) : (value/1000000000).toFixed(1)) + ' bilhões' // VERIFICACAO SE TEM DECIMAL :)
    } else if(value >= 1000000) {
        return (value/1000000) + ' milhões'
    } else if(value >= 1000) {
        return (value % 1 === 0 ? (value/1000) : (value/1000).toFixed(1)) + ' mil' // VERIFICACAO SE TEM DECIMAL :) 
    }
}

export default formatExtensive;