/* eslint-disable react/no-array-index-key */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable linebreak-style */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-shadow */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-nested-ternary */
import '../JackpotModal/style.scss';
import { groupBy } from '../JackpotModal/helper';
import { resultIcon } from '../../constants/result-status';
import fourLeafComplement from '../../assets/images/four-leaf-complement.svg';

/**
 * pegar e formatar dezenas
 * @param param0
 */
export default function gameColumns({
  game = null, isResult, type = null, draw = 'first',
}) {
  const hasPrizeResult = game.prize_result !== '';

  // const dezenas = helper.formatGameNumber(game, type)
  const gamePrizeStatus = (hasPrizeResult) ? (draw === 'second') ? game.prize_result?.dezenas.status : game.prize_result?.dezenas_segundo_sorteio.status : '';
  const icon = (hasPrizeResult) ? resultIcon[gamePrizeStatus] : '';

  //   const isWinner = (hasPrizeResult)
  //     ? game.prize_result?.dezenas.status === 'winning'
  //     || game.prize_result?.dezenas_segundo_sorteio.status === 'winning'
  //     || game.prize_result?.complement.status === 'winning'
  //       ? 'winning' : ''
  //     : ''

  function checkIfWinner(number, currentCol) {
    const numberByColumn = game.caixa_result?.dezenas.split(' ')[currentCol];
    return numberByColumn.includes(number) ? 'active' : ''; // VALIDO SOMENTE PARA O JOGO SUPER SETE.
  }

  function getGameFormatedCol() {
    let columns = '';
    let columnsSecond = '';
    let layoutCol = '';
    switch (type) {
      case 'super-sete':
        columns = game.dezenas.split(' ').map((dezena, index) => (
          <div key={index} className="super-sete-box">
            <span className="col-number">{index + 1}</span>
            {
            dezena.split('').map((numero, posicaoLetra) => {
              if (numero === 'x') {
                return;
              }
              return (
                <span key={Math.random()} className={`coluna-${index + 1} numero-${numero} posicao-${index} number ${game.caixa_result && checkIfWinner(numero, index)}`}>
                  {numero}
                </span>
              );
            })
          }
          </div>
        ));
        break;

      default:
        columns = game.dezenas.split(' ').map((number, index) => (
          <span key={index} className={`number ${game.caixa_result && game.caixa_result.dezenas?.includes(number) ? 'active' : ''}`}>
            {number}
          </span>
        ));
        if (game.caixa_result?.dezenas_segundo_sorteio) {
          columnsSecond = game.dezenas.split(' ').map((number, index) => (
            <span key={index} className={`number ${game.caixa_result && game.caixa_result.dezenas_segundo_sorteio?.includes(number) ? 'active' : ''}`}>
              {number}
            </span>
          ));
        }

        break;
    }

    function getComplementNumbers() {
      const secondDraw = type === 'dupla-sena' && game.prize_result?.dezenas_segundo_sorteio.status !== 'waiting';
      if (!game.complement) {
        return null;
      }

      if (type === 'mais-milionaria') {
        const allComplements = game.complement.split(' ');
        return allComplements.map((element) => (
          <div className="complement">
            {element}
          </div>
        ));
      }

      if (secondDraw) {
        return (
          <span className={`complement ${
            game.caixa_result
              && game.caixa_result.complement.toLowerCase()
                  === game.complement.toLowerCase()
              ? 'active'
              : ''}
              `}
          >
            {game.complement}
          </span>
        );
      }

      return (
        <span className="complement">
          {game.complement}
        </span>
      );
    }

    layoutCol = (
      <>
        <div className="first-draw">
          {isResult && (
            <div className="info">
              {game.prize_result?.dezenas.status !== 'waiting' && type === 'dupla-sena' && <span>1° Sorteio</span>}
              <img
                src={resultIcon[game.prize_result && (game.prize_result?.complement.status === 'winning' ? game.prize_result?.complement.status : game.prize_result?.dezenas.status)]}
                alt=""
                className="icon-size"
              />
            </div>
          )}
          <div className="game-cols">
            <div className="numbers">
              {columns}
            </div>
            <div className={`${type === 'mais-milionaria' ? 'complement-container' : ''}`}>
              {type === 'mais-milionaria' && <span>Trevos:</span>}
              <div className="complements">
                {getComplementNumbers()}
              </div>
            </div>
          </div>
        </div>
        {hasPrizeResult && game.prize_result?.dezenas_segundo_sorteio.status !== 'waiting' && type === 'dupla-sena' && (
          <div className="second-draw">
            {isResult && (
            <div className="info">
              <span>2° Sorteio</span>
              <img
                src={resultIcon[(game.prize_result && game.prize_result?.dezenas_segundo_sorteio.status)]}
                alt=""
                className="icon-size"
              />
            </div>
            )}
            <div className="numbers">
              {columnsSecond}
              {getComplementNumbers()}
            </div>
          </div>
        )}

      </>
    );

    return layoutCol;
  }

  return (
    <div className="game-numbers">
      {getGameFormatedCol()}
    </div>
  );
}
