import React from 'react';
import termsService from '../../services/terms'

import './style.scss';

class AcceptTerms extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            term: {}
        }
    }
    async componentDidMount() {
        const term = await termsService.getTerms();

        await this.setState({ 
            term,
        });
    }
    render() {
        const { term } = this.state;
        const formatDate = (value) => {
            return new Date(value).toLocaleDateString('pt-BR', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit'
            });
        }
        return (
            <main id="terms-and-conditions">
                <div className="container">
                    <div className="row">
                        <h1>{term.name}</h1>
                        <p className="term-date">{formatDate(term.updated_at)}</p>
                        <p>
                            Olá usuário, Por favor, leia o seguinte Acordo de Termos 
                            de Uso e Limitação de Responsabilidade antes de usar o 
                            unindosonhos.com.br. <span>A aceitação desse termo é obrigatória 
                            para o uso dos serviços do Unindo Sonhos.</span>
                        </p>
                    </div>
                    <div className="row">
                        <div className="content" dangerouslySetInnerHTML={{ __html: term.content_text }}></div>
                    </div>
                    <button onClick={() => termsService.acceptTerms()} type="button" class="green">Aceito os termos</button>
                </div>
            </main>
        );
    }
}
export default AcceptTerms;