import React from 'react';
import SimpleReactValidator from 'simple-react-validator';

import Alert    from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';

import { history }     from '../../helpers/history';
import Button          from '../../components/Button';
import AuthService     from '../../services/auth';

import logo from '../../assets/images/logotipo-unindo-sonhos.png';
import './style.scss';

class ForgotPassword extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            email:    '',
            feedback: { show: false, severity: 'info', message: '' },
            loading:  false
        }

        const _params = {
            messages: { 
                required: "Esse campo é obrigatório",
                email:    "Esse e-mail é inválido",
            }
        };

        this.handleChange = this.handleChange.bind(this);
        this.send         = this.send.bind(this);
        this.validator    = new SimpleReactValidator(_params);
    }

    handleChange(event) {
        this.setState({ ...this.state, email: event.target.value });
    }

    async send(event) {
        event.preventDefault();

        if(!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return;
        }

        this.setState({ ...this.state, loading: true });
        const { message } = await AuthService.rescuePassword(this.state.email);
        this.setState({ 
            ...this.state, 
            loading:  false, 
            feedback: { show: true, severity: 'info', message: message || 'As instruções foram enviados para o seu e-mail com sucesso!' }
        });
        setTimeout(() => this.setState({ ...this.state, feedback: { show: false, severity: 'info', message: '' }}), 5000);
        setTimeout(() => history.goBack(), 6000);
    }

    render() {
        const { feedback, email, loading } = this.state;
        return (
            <main id="forgot-password">

                <figure onClick={ event => history.push('/') }>
                    <img src={ logo }   alt="Logotipo da Unindo Sonhos" />
                </figure>
            
                <div className="box">

                    <form id="formulary" onSubmit={ this.send } noValidate>

                        <div className="form-group">
                            <h1>Esqueceu sua senha?</h1>
                            <p className="description">
                                Não tem problema, peça a recuperação de senha pelo formulário abaixo
                                e receba as instruções diretamente no seu e-mail!
                            </p>
                            <Collapse className="feedback" in={ feedback.show }>
                                <Alert severity={ feedback.severity }>{ feedback.message }</Alert>
                            </Collapse>
                        </div>

                        <div className="form-group">
                            <label>E-mail*</label>
                            <input type="email" className="form-control" value={ email } onChange={ event => this.handleChange(event, 'email') } />
                            { this.validator.message('E-mail', this.state.email, 'required|email') }
                        </div>

                        <div className="form-group actions">
                            <Button type="submit" disabled={ loading } loading={ loading } color="green" label="Solicitar recuperação da senha" />
                        </div>

                    </form>
                    
                    <span className="goBack link" onClick={ event => history.goBack() }>Voltar</span>

                </div>
            </main>
        )
    }

}

export default ForgotPassword;