/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable camelcase */
/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import { NavLink } from 'react-router-dom';

import Jackpots from '../../sections/Jackpots';
import About from '../../sections/About';
import HowWork from '../../sections/HowWork';
import TotalPrizes from '../../sections/TotalPrizes';
import Reviews from '../../sections/Reviews';

import 'swiper/swiper.scss';
import './style.scss';

class Home extends React.Component {
  render() {
    const concourse_id = this.props.match.params.bolao;
    return (
      <main id="jackpots">
        <div className="title">
          <div className="container">
            <div className="row centered">
              <h1>Escolha o seu bolão</h1>
              <button className="button styled-button"><NavLink to="/" activeStyle={{ color: '#FFFFFF' }}>Outras loterias</NavLink></button>
            </div>
          </div>
        </div>

        <Jackpots concourse_id={concourse_id} />
        <About />
        <TotalPrizes />
        <HowWork />
        <Reviews />
      </main>
    );
  }
}
export default Home;
