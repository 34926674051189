import React from 'react';
import { history } from '../../helpers/history';

import Button from '../../components/Button';

import './style.scss';

class SectionLetsPlay extends React.Component {
  render() {
    return (
      <section id="section-lets-play">
        <div className="container">
          <div className="row">

            <div className="content">
              <h1>
                Faça a sua aposta e
                <br />
                concorra a prêmios.
              </h1>
              <Button type="button" label="Escolher um bolão" color="invert" action={() => history.push('/boloes')} />
            </div>

          </div>
        </div>
      </section>
    );
  }
}

export default SectionLetsPlay;
