import Axios from 'axios';

const publicRequest = Axios.create({ baseURL: process.env.REACT_APP_ON_BOARDING_API });

const depositionsService = {
  async getApprovedDepositions() {
    try {
      const { data } = await publicRequest.get('/home-testimonials');
      return data;
    } catch (error) {
      return false;
    }
  },
};
export default depositionsService;
