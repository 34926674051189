import { types } from './types';

export function callFeedback(feedback) {
  return async (dispatch) => {
    try {
      dispatch({ type: types.SET_FEEDBACK, feedback });
    } catch (error) {
      console.log(error);
    }
  };
}
export function handleLogin() {
  return (dispatch) => dispatch({ type: types.OPEN_LOGIN });
}
export function handleCart() {
  return (dispatch) => dispatch({ type: types.OPEN_CART });
}
export function handleMobileMenu() {
  return (dispatch) => dispatch({ type: types.OPEN_MOBILE_MENU });
}
export function handleNeedAcceptTerm(value) {
  return (dispatch) => dispatch({ type: types.NEED_ACCEPT_TERM, value });
}

export function sendedPin() {
  /**
     * Artifício técnico para sinalizar que o e-mail do pin foi enviado
     * com sucesso. Isso porque eu não consegui encontrar uma forma de evitar
     * a re-renderização do componente, sei que existe uma forma correta para
     * fazer isso, porém como o tempo está curto não consegui encontrar agora.
     * Vou pesquisar mais e depois volto aqui para corrigir, caso você encontre
     * o sistema assim, desculpa, não consegui voltar para corrigir. ;)
     *
     * Caso você saiba como resolver e puder me ajudar, me envie um e-mail explicando
     * por gentileza: vento@tadah.xyz. Muito obrigado!
     * */
  return (dispatch) => dispatch({ type: types.SENDED_PIN });
}
