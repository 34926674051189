/* eslint-disable import/prefer-default-export */
export const types = {
  ADD_TO_CART: 'ADD_TO_CART',
  ADD_CREDITS: 'ADD_CREDITS',
  REMOVE_TO_CART: 'REMOVE_TO_CART',
  UPDATE_CART: 'UPDATE_CART',
  CLEAR_CART: 'CLEAR_CART',
  CLEAR_CART_CREDITS: 'CLEAR_CART_CREDITS',
  SET_KEY: 'SET_KEY',
  UPDATE_EXPIRY: 'UPDATE_EXPIRY',
  EXPIRE_CART_FEEDBACK: 'EXPIRE_CART_FEEDBACK',
};
