export default function ColunasBolao({
  bet = null, jackpot = null, isResult = false, games = null, caixaResult = false,
}) {
  function defaultColumns() {
    const columns = games.dezenas.split(' ').map((item, index) => (
      <span key={index} className="number">
        {item}
      </span>
    ));

    return columns;
  }
  function SuperSeteCol() {
    const dezenas = (caixaResult ? bet.caixa_result.dezenas : games.dezenas).split(' ');
    let colunas = '';
    if (caixaResult) {
      colunas = Array.from(dezenas)
        .map((number, index) => (
          <div key={index} className="super-sete-box">
            <span className="col-number">{index + 1}</span>
            <span key={index} className="number">
              {number}
            </span>
          </div>
        ));
    }
    return colunas;
  }
  function verifyIfResultIsSpecial() {
    switch (bet?.type.slug) {
      case 'super-sete':
        return SuperSeteCol();
      default:
        const columns = bet && bet.caixa_result?.dezenas.split(' ').map((item, index) => <span className="number" key={index}>{item}</span>);
        return columns;
    }
  }
  function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  return (
    <>
      {caixaResult && (
        <div className="caixa-result">
          {verifyIfResultIsSpecial()}
          {bet && bet.caixa_result.complement && (
            <span className="complement active" style={{ alignSelf: 'center' }}>
              {bet.caixa_result.complement.toUpperCase()}
            </span>
          )}
        </div>
      )}
    </>
  );
}
