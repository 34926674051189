import axios from 'axios';

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_ON_BOARDING_URL,
});

export default async function useRedis(key, content = '', method = 'POST') {
  if (method === 'DELETE') {
    return axiosClient.delete('/api/cache', {
      data: {
        keys: key,
      },
    }).then(({ data }) => data.content);
  }

  if (!content) {
    return axiosClient.get('/api/cache', {
      params: {
        key,
      },
    }).then(({ data }) => data.content);
  }

  return axiosClient.post('/api/cache', {
    key,
    content,
  }).then(({ data }) => data.success);
}
