/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
import * as React from 'react';
import './style.scss';

export default function Checkbox({
  label, withLink, onLinkClick, disabled, checked = false, onClick,
}) {
  const id = Math.random().toString(36).substring(7);
  return (
    <div className={disabled ? 'disabled' : ''}>
      <svg className="checkbox-symbol">
        <symbol id="check" viewBox="0 0 12 10">
          <polyline
            points="1.5 6 4.5 9 10.5 1"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </symbol>
      </svg>

      <div className="checkbox-container">
        <input disabled={disabled} className="checkbox-input" id={id} type="checkbox" checked={checked} />
        <label className="checkbox" htmlFor={id}>
          <span className="label-box" onClick={onClick}>
            <svg>
              <use xlinkHref="#check" />
            </svg>
          </span>
          <p>
            <span style={{ marginRight: 4 }} onClick={onClick}>
              {label}
            </span>
            {withLink && (
              <span style={{ color: '#209869' }} onClick={onLinkClick}>
                {withLink}
              </span>
            )}
          </p>
        </label>
      </div>
    </div>
  );
}
