/* eslint-disable react/jsx-filename-extension */
/* eslint-disable linebreak-style */
import React, { useEffect, useState } from 'react';
import SwiperCore, { Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import alexandre from '../../assets/images/reviews/alexandre-lima.jpg';
import gabriela from '../../assets/images/reviews/gabriela-alvarenga.jpg';
import thais from '../../assets/images/reviews/thais-ribeiro.jpg';

import depositionsService from '../../services/depositions';

import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';

import './style.scss';

export default function Reviews() {
  SwiperCore.use([Pagination, Autoplay]);
  const [approvedDepositions, setApprovedDepositions] = useState([]);

  useEffect(() => {
    const asynFunc = async () => {
      const { data } = await depositionsService.getApprovedDepositions();
      setApprovedDepositions(data);
    };
    asynFunc();
  }, []);

  return (
    <section id="section-reviews">
      <div className="container">
        <div className="row">
          <h1>O que nossos clientes dizem</h1>
        </div>
        <div className="row">
          <Swiper
            spaceBetween={20}
            slidesPerView={1}
            loop
            autoplay={{ delay: 5000 }}
            pagination={{ el: '.swiper-pagination-ext', clickable: true }}
          >
            <SwiperSlide>
              <div className="box">
                <p>
                  Eu sempre joguei sozinha nas lotéricas da minha cidade mas quando
                  a Paulinha anunciou no Instagram que ia fazer bolões eu nunca mais
                  precisei ir nas lotéricas. O primeiro bolão que ela fez parecia final
                  da copa do mundo todo mundo torcendo junto. Que Vibe!!!! Desde então
                  eu não perco um bolão com, ela anuncia que vai ter jogo eu quero participar.
                  Uma hora vamos pegar o prêmio principal. Eu confio muito na Paulinha e na
                  equipe dela. ❤️
                </p>
                <figure>
                  <img src={gabriela} alt="Foto da pessoa que enviou o comentário." />
                </figure>
                <span>
                  <strong>Gabriela Alvarenga</strong>
                  {' '}
                  - Niterói - RJ
                </span>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="box">
                <p>
                  Gosto de apostar com a Unindo Sonhos, pois são transparentes do
                  começo ao fim, me deram todo suporte necessário e todas minhas
                  dúvidas sempre foram sanadas, afinal eu não entendia nem como
                  funcionava um bolão.
                </p>
                <figure>
                  <img src={alexandre} alt="Foto da pessoa que enviou o comentário." />
                </figure>
                <span>
                  <strong>Alexandre Lima</strong>
                  {' '}
                  - Santos - SP
                </span>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="box">
                <p>
                  Tive a chance de participar de todos os bolões da unindo os sonhos
                  lançados até o momento, sempre fui muito bem atendida pela equipe
                  que sanaram todas as minhas dúvidas além de serem super atenciosos.
                  Com a unindo os sonhos tive a chance de conhecer a Paulinha, uma
                  pessoa maravilhosa que está por trás dos jogos e de uma energia
                  incrível, além de ter conhecido pessoas maravilhosas. Queria
                  muito agradecer por todos os bolões até o momento e por ter essa
                  equipe por trás da Unindo os sonhos e com certeza participarei dos
                  próximos bolões pelo site.
                </p>
                <figure>
                  <img src={thais} alt="Foto da pessoa que enviou o comentário." />
                </figure>
                <span>
                  <strong>Thais Silva Ribeiro</strong>
                  {' '}
                  - São Paulo - SP
                </span>
              </div>
            </SwiperSlide>
            {approvedDepositions?.map((depoiment) => (
              <SwiperSlide>
                <div className="box">
                  <p>
                    {depoiment.statement}
                  </p>
                  <figure>
                    <img src={depoiment.user_avatar} alt="Foto da pessoa que enviou o comentário." />
                  </figure>
                  <span>
                    <strong>{depoiment.user_name}</strong>
                    {depoiment.city && ` - ${depoiment.city} - ${depoiment.state}`}
                  </span>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="swiper-pagination-ext" />
        </div>
      </div>
    </section>
  );
}
