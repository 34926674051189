import React from 'react';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { history } from '../../helpers/history';
import currencyFormat from '../../helpers/currencyFormat';

import IconThrophy from '../../assets/images/throphy.svg';
import logo from '../../assets/images/icon-loteria.svg';

import statistcsService from '../../services/statistics';

import Button from '../../components/Button';

import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/navigation/navigation.scss';

import './style.scss';

class TotalPrizes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      prizes: [],
    };
    SwiperCore.use([Navigation, Pagination, Autoplay]);
  }

  async componentDidMount() {
    const additionalValues = [
      {
        name: 'Mega sena',
        slug: 'mega-sena',
        total: 510000.00,
      },
      {
        name: 'Lotofacil',
        slug: 'lotofacil',
        total: 2791889.55,
      },
    ];

    const prizes = await statistcsService.getPrizes();

    additionalValues.map((add) => {
      let found = false;

      for (let index = 0; index < prizes.categories.length; index++) {
        if (add.slug === prizes.categories[index].slug) {
          prizes.categories[index].total = parseFloat(prizes.categories[index].total) + add.total;
          found = true;
        }
      }

      if (!found) {
        prizes.categories.push(add);
      }

      prizes.total = parseFloat(prizes.total) + add.total;
    });

    await this.setState({
      ...this.state,
      prizes: prizes && prizes ? prizes : [],
    });
  }

  render() {
    const { prizes } = this.state;

    const _type = {
      'dia-de-sorte': 'Dia de sorte',
      'dupla-sena': 'Dupla sena',
      loteca: 'Loteca',
      lotomania: 'Lotomania',
      lotofacil: 'Lotofacil',
      quina: 'Quina',
      timemania: 'Timemania',
      'mega-sena': 'Mega sena',
      'mega-sena-da-virada': 'Mega sena da virada',
      'mais-milionaria': 'Mais milionária',
      'super-sete': 'Super sete',
    };

    return (
      <section id="section-total-prizes">
        <div className="total-container">
          <figure><img src={IconThrophy} alt="Ícone de troféu" /></figure>
          <span className="total-prizes">{currencyFormat(prizes.total)}</span>
          <span id="total-winner-draws">prêmios pagos pela unindo sonhos</span>

          <Swiper
            cssMode
            slidesPerView={5}
            spaceBetween={50}
            navigation
            className="swiper"
            breakpoints={{
              100: {
                slidesPerView: 1,
                spaceBetween: 5,
              },
              640: {
                slidesPerView: 2,
                spaceBetween: 5,
              },
              1320: {
                slidesPerView: 3,
                spaceBetween: 5,
              },
              1820: {
                slidesPerView: 5,
                spaceBetween: 5,
              },
            }}
          >
            {
              prizes.categories && prizes.categories.length
                ? prizes.categories.map((item) => (
                  <SwiperSlide key={item.id}>
                    <div className={`total-box ${item.slug}`}>
                      <img src={logo} alt="logotipo das loterias federais" width="20" />
                      <span className="total-game-prize">{currencyFormat(item.total)}</span>
                      <span className="total-game-description">pagos para apostas ganhadoras da</span>
                      <span className="total-game-type">{_type[item.slug]}</span>
                      <Button margin="0.7rem" color="green" action={() => history.push('/boloes')} label="Comprar cotas" />
                    </div>
                  </SwiperSlide>
                ))
                : ''
            }
          </Swiper>
          * valores pagos referentes a todas as plataformas Unindo sonhos, valores atualizados periodicamente.

        </div>
      </section>
    );
  }
}

export default TotalPrizes;
