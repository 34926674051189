/* eslint-disable no-underscore-dangle */
import api from './api'

const bettings = {
  async getBettings(page = null) {
    try {
      let _url = '/customer/bettings'
      _url += page ? `?page=${page}` : ''

      const data = await api.post(_url)
      if (data.status !== 200) {
        return false
      }
      return data.data
    } catch (error) {
      console.log(error)
      return false
    }
  },
  async viewBet(id) {
    try {
      const _url = `/customer/bettings/${id}`

      const { data } = await api.post(_url)
      return data.data
    } catch (error) {
      console.log(error)
      return false
    }
  },
  async getBetGames(id) {
    try {
      const _url = `/customer/bettings/${id}/games`

      const data = await api.post(_url)
      return data ? data.data.data : {}
    } catch (error) {
      console.log(error)
      return false
    }
  },
}
export default bettings
