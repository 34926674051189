import React from 'react';

import './style.scss';

class NotFound extends React.Component {
    render() {
        return (
            <main id="not-found">
                <div className="container">
                    <div className="row">

                        <h1>Página não encontrada</h1>
                        <p>
                            Infelizmente não encontramos essa página, pode ser por um erro ou
                            a página que você procura não exista. Revise por gentileza o endereço
                            digitado e tente novamente.
                        </p>

                    </div>
                </div>
            </main>
        );
    }
}
export default NotFound;