import Axios from 'axios';
import api   from './api';

const BASE_URL      = process.env.REACT_APP_API_BASE_URL;
const publicRequest = Axios.create({ baseURL: BASE_URL });
const auth = {
    async authenticate(user_id, password) {
        try {
            const { data } = await publicRequest.post('/login', { user_id, password }); 
            return data;
        }
        catch(error) {
            console.log(error);
            return false;
        }
    },
    async register(userData) {
        try {
            const { data } = await publicRequest.post('/register', userData);
            return data;
        }
        catch(error) {
            return false;
        }
    },
    async rescuePassword(email) {
        try {
            const { data } = await publicRequest.get('password/lost', { params: { email }});
            return data;
        }
        catch(error) {
            return false;
        }
    },
    async sendPin(email) {
        try {
            const { data } = await api.post('/email-verification', { email });
            return data;
        }
        catch(error) {
            return false;
        }
    },
    async validatePin(dataValidation) {
        try {
            const { data } = await api.post('/email-verification/'+ dataValidation.pin +'/'+ dataValidation.email);
            return data;
        }
        catch(error) {
            return false;
        }
    },
    async resetPassword(newPassword) {
        try {
            const { data } = await publicRequest.post('/password/reset', newPassword);
            return data;
        }
        catch(error) {
            if(error.response.status === 422)
                return { token: false, message: "Sua chave de criptografia é inválida." }
            
            return false;
        }
    }
    
}
export default auth;